import { defineStore } from 'pinia'
import { getClientTypeList, ClientTypeParams } from '@/api/system/clientType'
import { ClientTypeItem } from './typing'
export const SystemClientType = defineStore('SystemClientType', {
  state: () => {
    return {
      list: [] as Array<ClientTypeItem>
    }
  },
  getters: {
    clientTypeList: state => state.list as Array<ClientTypeItem>
  },
  actions: {
    async getList(ClientTypeParams: ClientTypeParams) {
      try {
        const res = await getClientTypeList(ClientTypeParams)
        const {
          data: { items },
          succeeded
        } = res
        items.forEach(item =>
          item.name === '个人客户' || item.name === '个人'
            ? (item.idCardTypeList = [
                { id: 1, name: '身份证' },
                { id: 3, name: '护照' },
                { id: 4, name: '港澳居民来往内地通行证' },
                { id: 5, name: '台胞证' }
              ])
            : (item.idCardTypeList = [
                { id: 2, name: '统一社会信用代码证' },
                { id: 6, name: '其他' }
              ])
        )
        succeeded ? (this.list = items) : ''
        return res
      } catch (error) {
        return { data: { items: [], totalCount: 0 }, succeeded: false }
      }
    }
  }
})
