import { Component } from 'vue'

import { MenuDataItem } from '@/router/typing'
import RouteView from '@/layouts/routeView.vue'

export const Complaint: MenuDataItem = {
  path: 'complaint',
  name: 'manage-complaint',
  meta: { title: '投诉管理', role: 'Admin.Complaint', icon: 'oa_tousu', fullPath: '/manage/complaint' },
  component: RouteView,
  redirect: '/manage/complaint/list',
  children: [
    {
      path: 'businesslist',
      name: 'manage-complaint-businesslist',
      meta: { icon: 'oa_tousu', role: 'Admin.Complaint.Business', title: '业务投诉', fullPath: '/manage/complaint/businesslist' },
      component: (): Component => import('@/views/manage/complaint/business/list.vue')
    },
    {
      path: 'businessguide',
      name: 'manage-complaint-businessguide',
      meta: { icon: 'oa_tousu', role: 'Admin.Complaint.Business', hideInMenu: true, title: '添加业务投诉引导页', fullPath: '/manage/complaint/businessguide' },
      component: (): Component => import('@/views/manage/complaint/business/guide.vue')
    },
    {
      path: 'businesscreate/:contractId(\\d+)',
      name: 'manage-complaint-businesscreate',
      meta: { icon: 'oa_tousu', role: 'Admin.Complaint.Business', hideInMenu: true, title: '添加业务投诉', fullPath: '/manage/complaint/businesscreate' },
      component: (): Component => import('@/views/manage/complaint/business/createOrUpdate.vue')
    },
    {
      path: 'businessupdate/:id(\\d+)/:contractId(\\d+)',
      name: 'manage-complaint-businessupdate',
      meta: { icon: 'oa_tousu', role: 'Admin.Complaint.Business', hideInMenu: true, title: '业务投诉修改', fullPath: '/manage/complaint/businessupdate' },
      component: (): Component => import('@/views/manage/complaint/business/createOrUpdate.vue')
    },
    {
      path: 'businesshandle/:id(\\d+)/:contractId(\\d+)',
      props: true,
      name: 'manage-complaint-businesshandle',
      meta: { icon: 'oa_tousu', role: 'Admin.Complaint.Business', hideInMenu: true, title: '业务投诉处理', fullPath: '/manage/complaint/businesshandle' },
      component: (): Component => import('@/views/manage/complaint/business/handle.vue')
    },
    {
      path: 'businessinfo/:contractId(\\d+)',
      props: true,
      name: 'manage-complaint-businessinfo',
      meta: { icon: 'oa_tousu', role: 'Admin.Complaint.Business', hideInMenu: true, title: '业务投诉详情', fullPath: '/manage/complaint/businessinfo' },
      component: (): Component => import('@/views/manage/complaint/business/info.vue')
    },

    {
      path: 'nonbusinesslist',
      name: 'manage-complaint-non-business-list',
      meta: { icon: 'oa_tousu', role: 'Admin.Complaint.User', title: '非业务投诉', fullPath: '/manage/complaint/nonbusinesslist' },
      component: (): Component => import('@/views/manage/complaint/noBusiness/list.vue')
    },
    {
      path: 'nonbusinessguide',
      name: 'manage-complaint-nonbusinessguide',
      meta: { icon: 'oa_tousu', role: 'Admin.Complaint.User', hideInMenu: true, title: '添加非业务投诉引导页', fullPath: '/manage/complaint/nonbusinessguide' },
      component: (): Component => import('@/views/manage/complaint/noBusiness/guide.vue')
    },
    // {
    //   path: 'nonbusinesscreate/:userId(\\d+)',
    //   name: 'manage-complaint-nonbusinesscreate',
    //   meta: { icon: 'oa_tousu', role: 'Admin.Complaint.User', hideInMenu: true, title: '添加非业务投诉', fullPath: '/manage/complaint/nonbusinesscreate' },
    //   component: (): Component => import('@/views/manage/complaint/noBusiness/createOrUpdate.vue')
    // },
    // {
    //   path: 'nonBusinessupdate/:id(\\d+)/:userId(\\d+)',
    //   name: 'manage-complaint-nonbusinessupdate',
    //   meta: { icon: 'oa_tousu', role: 'Admin.Complaint.User', hideInMenu: true, title: '编辑非业务投诉', fullPath: '/manage/complaint/nonbusinessupdate' },
    //   component: (): Component => import('@/views/manage/complaint/noBusiness/createOrUpdate.vue')
    // },
    // {
    //   path: 'nonbusinesshandle/:id(\\d+)/:userId(\\d+)',
    //   props: true,
    //   name: 'manage-complaint-nonbusinesshandle',
    //   meta: { icon: 'oa_tousu', role: 'Admin.Complaint.User', hideInMenu: true, title: '非业务投诉处理', fullPath: '/manage/complaint/nonbusinesshandle' },
    //   component: (): Component => import('@/views/manage/complaint/noBusiness/handle.vue')
    // },
    {
      path: 'nonbusinessinfo/:userId(\\d+)',
      props: true,
      name: 'manage-complaint-nonbusinessinfo',
      meta: { icon: 'oa_tousu', role: 'Admin.Complaint.User', hideInMenu: true, title: '非业务投诉详情', fullPath: '/manage/complaint/nonbusinessinfo' },
      component: (): Component => import('@/views/manage/complaint/noBusiness/userInfo.vue')
    }
  ]
}
