import { getContractSource } from '@/api/system/contractSource'
import { defineStore } from 'pinia'
import { ContractSourceItem } from './typing'

export const SystemContractSource = defineStore('SystemContractSource', {
  state: () => {
    return {
      list: [] as Array<ContractSourceItem>
    }
  },
  getters: {
    contractSourceList: state => state.list as Array<ContractSourceItem>
  },
  actions: {
    async getList() {
      try {
        const res = await getContractSource()
        const { data, succeeded } = res
        succeeded ? (this.list = data) : ''
        return res
      } catch (error) {}
    }
  }
})
