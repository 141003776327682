import { defineStore } from 'pinia'
import { notification } from 'ant-design-vue'
import router from '@/router'

import { tokenTableName, refreshTokenTableName } from '@/config'
import { get, remove, removeAll, set } from '@/utils/cookies'

import { Permission } from '../permission'

import { getAccountCurrentUser, postAccountByCode, postAccountLogin } from '@/api/account/login'
import type { AccountInfo, LoginParams, LoginParamsForCode } from './typing'

export const Account = defineStore('Account', {
  state: () => {
    return {
      accessToken: '',
      refreshToken: '',
      id: undefined,
      userName: '', // 用户名
      realName: '', //真实姓名
      avatar: '', //头像
      sex: false, //性别
      email: '', //邮箱
      phoneNumber: '', //电话号码
      licenseNumber: '', //执业证号

      isLawyer: false, //人员类别
      userTypeId: 0,
      userTypeName: '',

      isEnabled: true, //用户状态
      lawfirmId: 0, // 律所
      lawfirmName: '',
      lawfirmFullName: '',
      organizationUnitId: 0, //组织机构
      organizationUnitName: '',
      organizationUnitFullName: '',

      extra: undefined
    } as AccountInfo
  },
  getters: {
    userId: state => state.id,
    userRealName: state => state.realName,
    userAvatar: state => state.avatar,
    userIsEnabled: state => state.isEnabled,
    userTypeIsLawyer: state => state.isLawyer,
    userLawfirmId: state => state.lawfirmId,
    userLawfirmName: state => state.lawfirmName,
    userOrganizationUnitId: state => state.organizationUnitId,
    userOrganizationUnitFullName: state => state.organizationUnitFullName
  },
  actions: {
    // 账号密码&验证码登录通用
    async login(info: LoginParams) {
      const res = await postAccountLogin(info)
      const { data, succeeded } = res
      if (succeeded) {
        const { accessToken, refreshToken } = data
        this.accessToken = accessToken
        this.refreshToken = refreshToken
        set(tokenTableName, accessToken)
        set(refreshTokenTableName, refreshToken)
        const returnUrl = get('returnUrl')
        returnUrl ? ((location.href = returnUrl), remove('returnUrl')) : (location.href = '/')
      } else {
        removeAll()
      }
      return succeeded
    },
    // 验证码登录
    async loginForCode(info: LoginParamsForCode) {
      const res = await postAccountByCode(info)
      const { data, succeeded } = res
      if (succeeded) {
        const { accessToken, refreshToken } = data
        this.accessToken = accessToken
        this.refreshToken = refreshToken
        set(tokenTableName, accessToken)
        set(refreshTokenTableName, refreshToken)
        const returnUrl = get('returnUrl')
        returnUrl ? ((location.href = returnUrl), remove('returnUrl')) : (location.href = '/')
      } else {
        removeAll()
      }
      return succeeded
    },
    logout() {
      this.accessToken = undefined
      this.refreshToken = undefined
      removeAll() // 清楚默认存储位置存储数据
      Permission().clear() // 登出权限清空
      set('returnUrl', location.pathname)
      this.clear()
      router.push({ path: '/account/login' })
    },
    async getAccoutInfo() {
      try {
        const res = await getAccountCurrentUser()
        const { data, succeeded } = res
        if (succeeded) {
          const {
            id = 0,
            userName = '',
            realName = '',
            avatar = '',
            email = '',
            phoneNumber = '',
            lawfirmId = 0,
            lawfirmName = '',
            isEnabled = true,
            licenseNumber = '',
            organizationUnitId = 0,
            isLawyer = false,
            userTypeName = '',
            sex = false
          } = data || {}

          this.id = id
          this.userName = userName
          this.realName = realName
          this.avatar = avatar || 'http://cdn.jingsh.com/oa/uploadfiles/user/avatar/2017/11/27/b2903567e91e485684d3b249246174f0.png'
          this.phoneNumber = phoneNumber
          this.lawfirmId = lawfirmId
          this.lawfirmName = lawfirmName
          this.isEnabled = isEnabled
          this.licenseNumber = licenseNumber
          this.organizationUnitId = organizationUnitId
          this.sex = sex
          this.email = email
          this.isLawyer = isLawyer
          this.userTypeName = userTypeName
          this.extra = { ...data }
          notification.success({
            message: '欢迎',
            description: `欢迎 ${realName} 回来`,
            duration: 1
          })
        } else {
          this.clear()
        }
      } catch (error) {
        this.id = undefined
        this.userName = ''
        this.realName = ''
        this.avatar = 'http://cdn.jingsh.com/oa/uploadfiles/user/avatar/2017/11/27/b2903567e91e485684d3b249246174f0.png'
        this.phoneNumber = ''
        this.email = ''
        this.extra = undefined
        throw new Error('登录信息错误')
      }
    },

    clear() {
      this.$reset()
    }
  }
})
