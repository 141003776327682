<template>
  <div class="page">
    <h1>您正在使用不受支持的浏览器</h1>
    <p> 这意味着在升级或更换浏览器前,你将无法访问此网站。</p>
    <div class="hr" />
    <h1>受支持的浏览器</h1>
    <p class="targetline">推荐使用以下浏览器的最新版本。如果你的电脑已有以下浏览器的最新版本则直接使用该浏览器极速模式访问即可。</p>
    <!-- before-browser -->
    <ul class="browser-list">
      <li>
        <a href="https://www.google.cn/intl/zh-CN/chrome/" target="_blank"> <img :src="chrome" width="72" height="72" alt="谷歌浏览器" />谷歌浏览器 <span>Google Chrome</span> </a>
      </li>
      <li>
        <a href="https://www.firefox.com.cn/" target="_blank"> <img :src="firefox" width="72" height="72" alt="火狐浏览器" />火狐浏览器<span>Mozilla Firefox</span> </a>
      </li>
      <li>
        <a href="https://www.microsoft.com/zh-cn/edge" target="_blank"> <img :src="edge" width="72" height="72" alt="微软浏览器" />微软浏览器<span>Microsoft Edge</span> </a>
      </li>
      <li>
        <a href="http://browser.360.cn/ee/" target="_blank"><img :src="jisu" width="72" height="72" alt="360极速浏览器" />360极速浏览器<span style="font-weight: bolder">请下载64位版本</span></a>
      </li>
    </ul>
    <!-- after-browser -->
    <div class="hr" />
    <h2>为什么会出现这个页面？</h2>
    <p>
      如果你不知道升级浏览器是什么意思,请请教一些熟练电脑操作的朋友。如果你使用的不是IE6/7/8/9/10,而是360极速浏览器、谷歌浏览器、火狐浏览器等,出现这个页面是因为你使用的不是该浏览器的最新版本,升级至最新即可。
    </p>
    <div class="hr" />
    <h2>请注意:Windows XP 及旧版 Internet Explorer 的支持服务已终止</h2>
    <p>
      自2016年1月12日起,微软不再为 IE 11 以下版本提供相应支持和更新。没有关键的浏览器安全更新，您的电脑可能易受有害病毒、间谍软件和其他恶意软件的攻击，它们可以窃取或损害您的业务数据和信息。请参阅
      <a href="https://support.dmeng.net/end-of-ie-support.html">微软对旧版 Internet Explorer 的支持服务已终止的说明</a> 。
    </p>
    <p>
      自2023年2月14日起,通过 Microsoft Edge 更新，在某些版本的 Windows 10 上永久禁用了不受支持的 Internet Explorer 11 (IE11) 桌面应用程序。请注意，此更新将在几天到一周的时间内推出，这是 Microsoft Edge
      更新的标准。<br />
      尚未从 IE11 重定向到 Microsoft Edge 的所有剩余消费者和商业设备都通过 Microsoft Edge 更新进行了重定向。用户将无法撤消更改。此外，从 IE11 到 Microsoft Edge 的重定向将作为所有未来 Microsoft Edge
      更新的一部分包含在内。<br />
      IE11 视觉参考，例如“开始”菜单和任务栏上的 IE11 图标，将在计划于 2023 年 6 月 13 日发布的 2023 年 6 月 Windows 月度安全更新版本(通常称为“B”版本)中删除。可选的非- 计划于 2023 年 5 月 23
      日发布的某些 Windows 10 版本的安全预览版也将删除 IE11 视觉参考。
    </p>
  </div>
</template>

<script lang="ts">
  export default {
    name: 'UpgradeBrowser'
  }
</script>
<script setup lang="ts">
  import chrome from '@/assets/browser/chrome.jpg'
  import firefox from '@/assets/browser/firefox.jpg'
  import edge from '@/assets/browser/edge.jpg'
  import jisu from '@/assets/browser/360.png'
</script>
<style scoped lang="less">
  a {
    text-decoration: none;
    color: #0072c6;
  }
  a:hover {
    text-decoration: none;
    color: #004d8c;
  }

  .page {
    width: 960px;
    margin: 0 auto;
    padding: 10px;
    text-align: left;
    p {
      margin-bottom: 10px;
    }
    .hr {
      margin: 20px 0;
      border: 0;
      width: 100%;
      height: 1px;
      overflow: hidden;
      background-color: #ccc;
    }

    h1 {
      font-size: 40px;
      line-height: 60px;
      font-weight: 100;
      margin: 20px 0 15px;
    }
    h2 {
      font-size: 20px;
      line-height: 25px;
      font-weight: 100;
      margin: 10px 0;
    }
    .browser-list {
      margin: 16px 0 10px;
      padding: 0;
      height: 72px;
      _overflow: hidden;
      li {
        display: block;
        width: 200px;
        height: 72px;
        line-height: 42px;
        float: left;
        list-style: none;
        span {
          display: block;
          font-size: 12px;
          line-height: 1.2;
        }
        img {
          width: 72px;
          height: 72px;
          border: 0;
          float: left;
          margin-right: 10px;
        }
      }
    }
  }

  @media only screen and (max-width: 959px) {
    .page {
      max-width: 100%;
      padding: 20px;
      font-size: 16px;
      line-height: 26px;
    }
    h1 {
      font-size: 26px;
      line-height: 1.8;
      font-weight: bold;
      margin-top: 0;
    }
    .browser-list {
      height: auto;
    }
    .browser {
      margin: 0 20px 20px 0;
    }
  }
</style>
