import { Component } from 'vue'

import { MenuDataItem } from '@/router/typing'
import RouteView from '@/layouts/routeView.vue'

export const Bid: MenuDataItem = {
  path: 'bid',
  name: 'manage-bid',
  meta: { title: '招投标管理', role: 'Admin.Bid', icon: 'oa_tender', fullPath: '/manage/bid' },
  component: RouteView,
  redirect: '/manage/bid/index',
  children: [
    {
      path: 'index',
      name: 'manage-bid-index',
      meta: { icon: 'oa_tender', role: 'Admin.Bid', title: '招投标备案', fullPath: '/manage/bid/index' },
      component: (): Component => import('@/views/manage/bid/index.vue')
    },
    {
      path: 'info/:bidId(\\d+)',
      name: 'manage-bid-info',
      meta: { icon: 'oa_tender', role: 'Admin.Bid', hideInMenu: true, title: '招投标详情', fullPath: '/manage/bid/info' },
      component: (): Component => import('@/views/manage/bid/info.vue')
    },
    {
      path: 'update/:bidId(\\d+)',
      name: 'manage-bid-update',
      meta: { icon: 'oa_update', role: 'Admin.Bid', hideInMenu: true, title: '招投标编辑', fullPath: '/manage/bid/update' },
      component: (): Component => import('@/views/manage/bid/update.vue')
    },
    {
      path: 'pass/:bidId(\\d+)',
      name: 'manage-bid-pass',
      meta: { icon: 'oa_pass', role: 'Admin.Bid', hideInMenu: true, title: '招投标审核', fullPath: '/manage/bid/pass' },
      component: (): Component => import('@/views/manage/bid/pass.vue')
    }
  ]
}
