<template>
  <a-dropdown
    v-model:open="visible"
    class="ant-pro-dropdown ant-pro-dropdown-action"
    placement="bottomRight"
    :trigger="['click']"
    overlay-class-name="pro-components-header-notice-icon-index-container"
  >
    <span :class="['noticeButton', { opened: visible }]">
      <a-badge :count="props.count" :style="{ boxShadow: 'none' }" class="badge">
        <slot name="bell">
          <svg-icon name="oa_notification" :style="{ color: props.layout === 'manage' ? null : 'rgba(255,255,255,.85)', fontSize: '20px' }" />
        </slot>
      </a-badge>
    </span>
    <template #overlay>
      <div>
        <a-spin :spinning="props.loading" :delay="300">
          <slot />
        </a-spin>
      </div>
    </template>
  </a-dropdown>
</template>

<script setup lang="ts">
  import { PropType, ref } from 'vue'
  defineOptions({ name: 'NoticeDropdown' })
  const props = defineProps({
    layout: {
      type: String as PropType<'manage' | 'user'>,
      default: 'manage'
    },
    count: {
      type: Number
    },
    loading: {
      type: Boolean
    }
  })
  const visible = ref(false)
</script>

<style lang="less" scoped>
  .popover {
    position: relative;
    width: 336px;
  }

  .noticeButton {
    display: inline-block;
    cursor: pointer;
    transition: all 0.3s;
  }
  .icon {
    padding: 4px;
    vertical-align: middle;
  }

  .badge {
    font-size: 16px;
  }

  .tabs {
    :deep {
      .ant-tabs-nav-list {
        margin: auto;
      }

      .ant-tabs-nav-scroll {
        text-align: center;
      }
      .ant-tabs-bar {
        margin-bottom: 0;
      }
    }
  }
</style>
<style lang="less">
  .pro-components-header-notice-icon-index-container > * {
    position: relative;
    width: 336px;
    background-color: @popover-bg;
    border-radius: 4px;
    box-shadow: @shadow-1-down;
  }

  @media screen and (max-width: @screen-xs) {
    .pro-components-header-notice-icon-index-container {
      width: 100% !important;
    }
    .pro-components-header-notice-icon-index-container > * {
      width: 100% !important;
      border-radius: 0 !important;
    }
  }
</style>
