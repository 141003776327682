import request from '@/utils/request'
import { PageResultData, RequestResult, QueryParams } from '../typing'
import { ComplaintSourceCreateOrUpdate, ComplaintSourceItem } from '@/stores/modules/system/typing'
// import { CheckExist } from '@/stores/typing'

export type ComplaintSourceParams = {
  name?: string
} & QueryParams

/**
 * 获取当前用户所在律所投诉来源
 * @returns {RequestResult<PageResultData<ComplaintSourceItem>>}
 */
// export async function getComplaintSource() {
//   return request.get<null, RequestResult<Array<ComplaintSourceItem>>>('/api/ComplaintSource/getList')
// }

/**
 * 投诉来源
 * 获取投诉来源列表
 * @param {ComplaintSourceParams} ComplaintSourceParams
 * @returns {PageResultData<ComplaintSourceItem>}
 */
export async function getComplaintSourceList(ComplaintSourceParams: ComplaintSourceParams) {
  return request.get<ComplaintSourceParams, RequestResult<PageResultData<ComplaintSourceItem>>>('/api/ComplaintSource/GetPagedList', { params: ComplaintSourceParams })
}

/**
 * 根据ID获取投诉来源详情
 * @param {number} id
 * @returns {RequestResult<ComplaintSourceItem>}
 */
export async function getComplaintSourceInfo(id: number) {
  return request.get<number, RequestResult<ComplaintSourceItem>>(`/api/ComplaintSource/GetById/${id}`)
}

/**
 * 检查是否重复
 * @param {CheckExist} CheckExist
 * @returns {RequestResult<ComplaintSourceItem>}
 */
// export async function checkComplaintSourceName(CheckExist: CheckExist) {
//   return request.post<CheckExist, RequestResult<boolean>>(`/api/ComplaintSource/CheckExist`, CheckExist)
// }

/**
 * 创建投诉来源
 * @param {ComplaintSourceCreateOrUpdate} data
 * @returns {RequestResult}
 */
export async function createComplaintSource(data: ComplaintSourceCreateOrUpdate) {
  return request.post<ComplaintSourceCreateOrUpdate, RequestResult<null>>(`/api/ComplaintSource/create`, data)
}

/**
 * 编辑投诉来源
 * @param {ComplaintSourceCreateOrUpdate} data
 * @returns {RequestResult}
 */
export async function updateComplaintSource(data: ComplaintSourceCreateOrUpdate) {
  return request.put<ComplaintSourceCreateOrUpdate, RequestResult<null>>(`/api/ComplaintSource/update`, data)
}

/**
 * 删除投诉来源
 * @param {ComplaintSourceItem} data
 * @returns {RequestResult}
 */
export async function deleteComplaintSource(id: number) {
  return request.delete<number, RequestResult<null>>(`/api/ComplaintSource/delete/${id}`)
}

/**
 * 软删除投诉来源
 * @param {ComplaintSourceItem} data
 * @returns {RequestResult}
 */
export async function deleteComplaintSourceSoft(id: number) {
  return request.post<number, RequestResult<null>>(`/api/ComplaintSource/SoftDelete/${id}`)
}
