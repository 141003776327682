<template>
  <pagination
    v-model:current="currentPage"
    v-model:page-size="currentPageSize"
    :page-size-options="pageSizeOptions"
    :show-quick-jumper="props.showQuickJumper"
    :show-size-changer="props.showSizeChanger"
    :hide-on-single-page="hideOnSinglePage"
    v-bind="$attrs"
    :show-total="(total: String | Number) => `共 ${total} 条`"
  />
</template>

<script setup lang="ts">
  /** 此组件完全为 Antd Pagination 组件,只是在此基础上调整了一些默认参数&方法 */
  /** 文档:https://antdv.com/components/pagination-cn/ */
  /** 文档:https://2x.antdv.com/components/pagination-cn/ */
  import { Pagination } from 'ant-design-vue'
  import { PropType, computed, ref, watch } from 'vue'
  import { hideOnSinglePage, pageSizeOptionsDefault } from '@/config/index'
  defineOptions({ name: 'PageIndex' })

  const props = defineProps({
    //    * props 接收参数基承 Antd Pagination所有参数
    //    * 调整如下
    //    * pageSize:为必传
    //    * showTotal:接收值为Number
    //    */
    pageSize: {
      type: Number,
      require: true
    },
    showConsole: {
      type: Boolean,
      require: false,
      default: false
    },
    pageSizeOptions: {
      type: Array as PropType<Array<string>>,
      default: undefined
    },
    showQuickJumper: {
      type: Boolean,
      default: true
    },
    showSizeChanger: {
      type: Boolean,
      default: true
    }
  })
  const emit = defineEmits(['pagination'])

  // 当前页码
  const currentPage = ref(1)

  // 每页条数
  const currentPageSize = ref(0)

  watch(
    () => props.pageSize,
    () => {
      props.pageSize ? (currentPageSize.value = props.pageSize) : ''
    },
    {
      immediate: true
    }
  )

  watch(
    () => currentPage,
    () => {
      emit('pagination', { page: currentPage.value, size: currentPageSize.value })
      props.showConsole ? console.log(`当前页码${currentPage.value},当前条数${currentPageSize.value}`) : ''
    },
    {
      deep: true
    }
  )

  watch(
    () => currentPageSize,
    () => {
      // 每页条数改变,页码自动跳转第一页,此处不使用emit,多次触发
      currentPage.value = 1
      props.showConsole ? console.log(`当前条数${currentPageSize.value},当前页码${currentPage.value}`) : ''
    },
    {
      deep: true
    }
  )

  const pageSizeOptions = computed(() => props.pageSizeOptions || pageSizeOptionsDefault).value
</script>

<style lang="less" scoped>
  .ant-pagination {
    margin-top: 30px;
    text-align: center;
  }
</style>
