<template>
  <svg :class="svgClass" aria-hidden="true">
    <use :xlink:href="iconName" />
  </svg>
</template>

<script setup lang="ts">
  import { computed } from 'vue'
  defineOptions({ name: 'SvgIcon' })
  const props = defineProps({
    name: {
      type: String,
      required: true
    }
  })
  const iconName = computed(() => `#icon-${props.name}`)
  const svgClass = computed(() => {
    if (props.name) {
      return `svg-icon icon-${props.name}`
    }
    return 'svg-icon'
  })
</script>

<style lang="less">
  /** svg icon */
  .svg-icon {
    width: 1em;
    height: 1em;
    vertical-align: -0.15em;
    fill: currentColor;
    overflow: hidden;
    & + span {
      margin-left: 4px;
    }
  }
</style>
