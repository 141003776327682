<template>
  <a-sub-menu :key="props.menu.meta.fullPath">
    <template #icon>
      <svg-icon :name="props.menu.meta.icon" color="rgba(255,255,255,0.85)" />
    </template>
    <template #title>
      <span>{{ props.menu.meta.title }}</span>
    </template>
    <template v-for="child in props.menu.children" :key="child.meta.fullPath">
      <!-- children hideChildrenInMenu  判断 menu 是否包含 children 或是否 需要隐藏 children  ｜  不包含children 或 需要隐藏 children 直接渲染-->
      <template v-if="!child.children || child?.meta?.hideChildrenInMenu">
        <!-- hideInMenu 判断 是否需要隐藏该项-->
        <a-menu-item v-if="!child?.meta?.hideInMenu" :key="child?.meta?.fullPath">
          <template #icon>
            <svg-icon :name="child?.meta?.icon || 'home'" color="rgba(255,255,255,0.85)" />
          </template>
          <template v-if="!child?.meta?.outside">
            <router-link :target="child?.meta?.target" :to="{ path: child?.meta?.fullPath }">
              <span>{{ child?.meta?.title }}</span>
            </router-link>
          </template>
          <template v-else>
            <a :target="child?.meta?.target" :href="child?.meta?.fullPath">
              <span>{{ child?.meta?.title }}</span>
            </a>
          </template>
        </a-menu-item>
      </template>
      <template v-else>
        <sub-menu :key="child.meta.fullPath" :menu="child" />
      </template>
    </template>
  </a-sub-menu>
</template>

<script setup lang="ts">
  import { PropType } from 'vue'
  import type { MenuDataItem } from '@/router/typing'
  defineOptions({ name: 'SubMenu' })
  const props = defineProps({
    menu: {
      type: Object as PropType<MenuDataItem>,
      required: true
    }
  })
</script>

<style scoped></style>
