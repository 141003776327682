import { getPaymentTypeList } from '@/api/system/paymentType'
import { defineStore } from 'pinia'
import { PaymentTypeItem } from './typing'
export const SystemPaymentType = defineStore('SystemPaymentType', {
  state: () => {
    return {
      list: [] as Array<PaymentTypeItem>
    }
  },
  getters: {
    paymentTypeList: state => state.list as Array<PaymentTypeItem>
  },
  actions: {
    async getList() {
      try {
        const res = await getPaymentTypeList()
        const { data, succeeded } = res
        succeeded ? (this.list = data) : ''
        return res
      } catch (error) {}
    }
  }
})
