import { Component } from 'vue'

import { MenuDataItem } from '@/router/typing'
import RouteView from '@/layouts/routeView.vue'

export const Knowlege: MenuDataItem = {
  path: 'tools',
  name: 'manage-tools',
  meta: { title: '知识管理', icon: 'oa_yewuguanli', fullPath: '/manage/tools' },
  component: RouteView,
  redirect: '/manage/tools/template',
  children: [
    {
      path: 'template',
      name: 'manage-tools-template',
      meta: { icon: 'oa_knowlage', title: '办公模板', fullPath: '/manage/tools/template' },
      component: (): Component => import('@/views/tools/template.vue')
    },
    {
      path: 'third',
      name: 'manage-tools-third',
      meta: { icon: 'oa_knowlage', title: '办公助手', fullPath: '/manage/tools/third' },
      component: (): Component => import('@/views/tools/third.vue')
    }
  ]
}
