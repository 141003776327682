<template>
  <a-dropdown v-if="stores.id" placement="bottomRight">
    <span>
      <a-avatar :src="stores.avatar" :size="28" />
      <span class="name">{{ stores.realName }}</span>
    </span>
    <template #overlay>
      <a-menu :selected-keys="[]">
        <a-menu-item key="center" @click="handleToCenter">
          <svg-icon name="oa_user_o" />
          个人中心
        </a-menu-item>
        <a-menu-item key="settings" @click="handleToSettings">
          <svg-icon name="oa_setting" />
          个人设置
        </a-menu-item>
        <a-menu-divider />
        <a-menu-item key="logout" @click="handleLogout">
          <svg-icon name="oa_Logout" />
          退出登录
        </a-menu-item>
      </a-menu>
    </template>
  </a-dropdown>
  <span v-else>
    <a-spin size="small" :style="{ marginLeft: 8, marginRight: 8 }" />
  </span>
</template>

<script setup lang="ts">
  import { useRouter } from 'vue-router'

  import { Account } from '@/stores/modules/account'
  defineOptions({ name: 'AvatarDropDown' })
  const stores = Account()

  const router = useRouter()

  const handleToCenter = () => {
    router.push({ path: '/myaccount' })
  }
  const handleToSettings = () => {
    router.push({ path: '/myaccount/settings' })
  }
  const handleLogout = () => {
    stores.logout()
  }
</script>

<style lang="less">
  .ant-pro-header-account-name {
    vertical-align: unset;
  }
  .ant-pro-header-account-avatar {
    margin: 12px 8px 12px 0;
    color: @colorPrimary;
    vertical-align: top;
    background: hsla(0, 0%, 100%, 0.85);
  }

  .name {
    vertical-align: unset;
    margin-left: 8px;
  }
</style>
