import { getClientSourceList } from '@/api/system/clientSource'
import { defineStore } from 'pinia'
import type { ClientSourceItem } from './typing'
import type { ClientSourceParams } from '@/api/system/clientSource'
export const SystemClientSource = defineStore('SystemClientSource', {
  state: () => {
    return {
      list: [] as Array<ClientSourceItem>
    }
  },
  getters: {
    clientSourceList: state => state.list as Array<ClientSourceItem>
  },
  actions: {
    async getList(ClientSourceParams: ClientSourceParams) {
      try {
        const res = await getClientSourceList(ClientSourceParams)
        const {
          data: { items },
          succeeded
        } = res
        succeeded ? (this.list = items) : ''
        return res
      } catch (error) {}
    }
  }
})
