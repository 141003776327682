import request from '@/utils/request'
import type { RequestResult } from '../typing'
import { AppLawfirmParams } from '@/stores/modules/appFunction/typing'

/**
 * 获取当前律所功能配置
 * @returns
 */
export const getLawfirmParams = (lawfirmId: number) => {
  return request.get<null, RequestResult<AppLawfirmParams>>(`/api/Config/GetLawfirmParams/${lawfirmId}`)
}
