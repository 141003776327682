<template>
  <a-layout>
    <template v-if="isMobile">
      <a-drawer :placement="'left'" :closable="false" :open="state.visible" :width="sideBarWidth" root-class-name="sidebardrawer" @close="close">
        <side-bar :menu="menu" />
      </a-drawer>
    </template>
    <side-bar v-else :menu="menu" />
    <a-layout class="content">
      <global-header @change-drawer="changeDrawer">
        <template #rightContent>
          <a-space :size="14">
            <notice-icon />
            <avatar-dropdown />
          </a-space>
        </template>
      </global-header>
      <!-- <MultiTab /> -->
      <a-layout-content>
        <a-spin :spinning="loading" :tip="loadingTitle">
          <router-view :key="$route.fullPath" />
        </a-spin>
      </a-layout-content>
      <global-footer />
    </a-layout>
  </a-layout>
</template>

<script setup lang="ts">
  import { reactive } from 'vue'
  import { storeToRefs } from 'pinia'
  import { loadingTitle } from '@/config'

  import SideBar from './components/sideBar/index.vue'
  import GlobalHeader from './components/globalHeader/index.vue'
  import GlobalFooter from './components/globalFooter/index.vue'

  import AvatarDropdown from './components/avatarDropdown.vue'
  import NoticeIcon from './components/noticeIcon/index.vue'

  import { AppStores } from '@/stores/modules/app'
  import { Permission } from '@/stores/modules/permission'
  defineOptions({ name: 'ManageLayout' })
  const stores = AppStores()
  const permissionStores = Permission()
  const { isMobile, sideBarWidth, loading } = storeToRefs(stores)

  const state = reactive({
    visible: false
  })
  const menu = permissionStores.allowManageRouters
  const changeDrawer = (value: boolean) => {
    state.visible = value
  }
  const close = () => {
    state.visible = false
    stores.menuCollapsed = true
  }
</script>
<style lang="less" scoped>
  .ant-layout {
    overflow: auto;
    .ant-layout-content {
      margin: 24px 16px 0;
      min-height: 90vh;
    }
  }
</style>
<style lang="less">
  .sidebardrawer {
    .ant-drawer-body {
      padding: 0 !important;
    }
  }
</style>
