import { h } from 'vue'
import axios, { AxiosRequestConfig, AxiosResponse, AxiosError, RawAxiosRequestHeaders } from 'axios'
import { Modal, notification } from 'ant-design-vue'
import qs from 'qs'

// Account Store
import { Account } from '@/stores/modules/account'
import { AppStores } from '@/stores/modules/app'
// 基础配置参数
import { loginAPIUrl, requestTimeout, tokenPrefix, tokenName, refreshTokenName, tokenTableName } from '@/config'
// 浏览器数据读写
import { set, get } from '@/utils/cookies'
// responst ts
import { ResponseBody } from '@/api/typing'
// 路由
import router from '@/router/index'

// 加载状态
const loadingState = (loading: boolean) => {
  AppStores().loading = loading
}

// 这里是用于设定请求后端时，所用的 Token KEY
// 可以根据自己的需要修改，常见的如 Access-Token，Authorization
// 需要注意的是，请尽量保证使用中横线`-` 来作为分隔符，
// 避免被 nginx 等负载均衡器丢弃了自定义的请求头
const REQUEST_TOKEN_KEY = tokenName
const REQUEST_REFRESH_TOKEN_KEY = refreshTokenName
// 请求头前缀
const REQUEST__TOKEN_PREFIX = tokenPrefix
// 创建 axios 实例
const request = axios.create({
  // API 请求的默认前缀
  baseURL: import.meta.env.VITE_APP_API_BASE_URL,
  timeout: requestTimeout // 请求超时时间
})

// 异常拦截处理器
const responseStatus = (status: number, res: any, error?: any) => {
  const { errors, config: { url } = { url: '' } } = res
  switch (status) {
    case 0:
      notification.error({ message: `未知错误: ${JSON.stringify(error)}` })
      break
    case 400:
      notification.error({ message: `请求错误: ${JSON.stringify(errors)}` })
      break
    // 401 登录过期需重新登录
    case 401:
      set('returnUrl', location.pathname)
      Account().logout() // 401 手动调用登出,做现有数据清空,路由跳转
      notification.error({ message: '您的登录信息已过期,请重新登录' })
      break
    // 403 无权限
    case 403:
      router.push('/error/403')
      notification.error({ message: '您没有权限访问' })
      break
    // 请求路径未找到
    case 404:
      notification.warning({ message: `请求数据访问地址:"${url}"不存在,请重新核对地址` })
      break
    // 服务器错误
    case 500:
      const {
        config: { url: requeseUrl }
      } = error
      Modal.error({
        title: '服务器错误',
        content: h('div', {}, [
          h('p', {}, [h('span', 'API接口'), h('span', { style: { marginLeft: '14px', color: 'red' } }, requeseUrl)]),
          h('p', { style: { color: 'red' } }, `${JSON.stringify(errors)}`),
          h('p', '请稍后重试!')
        ]),
        centered: true
      })
      break
    default:
      notification.error({ message: `未知错误: ${JSON.stringify(res)}` })
      break
  }
}

// 错误拦截器
const errorHandler = (error: AxiosError): Promise<any> => {
  if (error.response) {
    const { status } = error.response
    responseStatus(status, error.response, error)
  } else {
    notification.error({ message: error.message })
  }
  loadingState(false)
  return Promise.reject(error)
}

interface UserRequestHeaderConfig extends AxiosRequestConfig {
  url?: string
  headers: RawAxiosRequestHeaders & {
    [REQUEST_TOKEN_KEY]: string
    [REQUEST_REFRESH_TOKEN_KEY]: string
  }
  [key: string]: any
}

// 请求拦截器
const requestHandler = (config: UserRequestHeaderConfig): AxiosRequestConfig | Promise<AxiosRequestConfig> => {
  loadingState(true)
  const savedToken = get(tokenTableName)
  const savedRefreshToken = get(refreshTokenName)
  // 如果 token 存在
  // 让每个请求携带自定义 token, 请根据实际情况修改
  if (savedToken && config.url?.toLocaleLowerCase().indexOf(loginAPIUrl.toLocaleLowerCase()) === -1) {
    config.headers[REQUEST_TOKEN_KEY] = `${REQUEST__TOKEN_PREFIX}${savedToken}`
    config.headers[REQUEST_REFRESH_TOKEN_KEY] = `${REQUEST__TOKEN_PREFIX}${savedRefreshToken}`
  }
  if (config.method === 'get' && config.params) {
    // config.paramsSerializer = paramsSerializer(config.params)
    config.paramsSerializer = {
      serialize: (params: any) => {
        // get 请求参数 格式化 去除 null undefined & ''
        Object.keys(params).forEach((item: string) => {
          params[item] === '' || params[item] === undefined || params[item] === null ? delete params[item] : ''
        })
        return qs.stringify(params, { arrayFormat: 'repeat' })
      }
    }
  }
  return config
}

// 应用请求拦截器
request.interceptors.request.use(requestHandler, errorHandler)

// 响应拦截器
const responseHandler = (response: AxiosResponse): ResponseBody<any> | AxiosResponse<any> | Promise<any> | any => {
  response.headers['access-token'] && response.headers['x-access-token'] ? (set(tokenName, response.headers['access-token']), set(refreshTokenName, response.headers['x-access-token'])) : ''
  const {
    config: { responseType },
    data: { statusCode, succeeded }
  } = response
  succeeded || responseType === 'blob' ? '' : responseStatus(statusCode, response.data, response)
  loadingState(false)
  return response.data
}

// 应用响应拦截器
request.interceptors.response.use(responseHandler, errorHandler)

export default request
