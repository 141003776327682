import router from '@/router'
import { get } from '@/utils/cookies'
import { tokenTableName } from '@/config/index'
import { allowList, loginRoutePath } from '../defineMeta'
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css'
import { title, titleSeparator, titleReverse } from '@/config'

import { AppStores } from '@/stores/modules/app'
import { Permission } from '@/stores/modules/permission'

/**
 * @author qingyun zheng2222021713@hotmail.com
 * @description 设置标题
 * @param pageTitle
 * @returns {string}
 */
export default function getPageTitle(pageTitle: string): string {
  let newTitles: Array<string> = []
  if (pageTitle) newTitles.push(pageTitle)
  if (title) newTitles.push(title)
  if (titleReverse) newTitles = newTitles.reverse()
  return newTitles.join(titleSeparator)
}

router.beforeEach(async to => {
  NProgress.start() // start progress bar
  const userToken = get(tokenTableName)
  // token check 更加 token 判断是否进行登录
  // 页面刷新后 执行顺序 1 检查是否存在token
  if (!userToken) {
    // 白名单路由列表检查
    // 没有登录 检查 所访问页面是否需要登录
    if (allowList.includes(to.name as string)) {
      return true
    }
    // 未登录且访问地址不是登录地址,跳转登录
    if (to.fullPath !== loginRoutePath) {
      // 未登录，进入到登录页
      return {
        path: loginRoutePath,
        replace: true
      }
    }
    return to
  }

  const permissionStore = Permission()
  // check login user.role is null
  // 通过 store 获取是否存在允许访问路由
  if (permissionStore.allowRouters && permissionStore.allowRouters.length > 0) {
    // 页面刷新后 执行顺序 3 存在以生成路由 直接返回访问
    return true
  } else {
    // 从服务端获取用户的 [基础信息] 和 [权限信息]
    // 并构建动态路由和菜单
    // 问题1：为什么这么做：
    //   - 一般开发需要权限的系统时，都会有 登录 步骤
    //   - SPA 单页应用每次刷新时，内存中的数据都会被清空，如果每次刷新，执行一次 登录 步骤则非常不合理。
    //   - 所以设计为，一次登录，获得用户的授权 access token，并持久化到 localstorage，之后用户每次打开
    //     页面或者刷新页面时，都利用这个 token 去向后端索要用户的真实信息
    // 问题2：为什么不把用户信息也存到 localstorage 来少一次请求？
    //   - 用户信息存在 localstorage 后，使用者打开控制台，直接修改其中的权限信息，如：
    //     我的用户角色是 'user' ，这时改成 'admin'。刷新页面时就能看到 'admin' 才能看到的信息。所以该方案不可行！
    // 问题3：为什么不每次都调用登录？
    //   - 如果每次刷新都进行登录认证，那么用户的账户以及密码则不可保障安全
    //   - 要登录必然要账户密码或相同功能的认证信息代替
    // 问题4：access token 不是也不能保障安全吗？
    //   - 用户在此进行登录，代表认同该设备。保存用户的 token 可以进行快速身份认证，
    //     并且当用户认为 token 发生泄露或不安全时，可以根据相关服务端 token 设计规则，让 token 失效。
    // 生成路由必须要有用户权限，所以这里需要请求用户信息&功能权限信息，并生成路由表
    await AppStores().getAppDfaultMustData()

    // 页面刷新后 执行顺序 2 刷新后 store 数据清空 需根据相关信息进行重新生成
    // 无限循环请求 error !
    // 使用当前用户的 权限信息 生成 对应权限的路由表
    // 调用 生成路由 并传入 当前登录用户信息 , 根据当前登录用户信息,生成相关路由表
    const allowRouters = await permissionStore.getAccountGenerateRoutes()

    if (allowRouters) {
      return { ...to, replace: true }
    }
    return false
  }
})

router.afterEach(async (to: any) => {
  document.title = getPageTitle(to.meta.title)
  NProgress.done() // finish progress bar
  // 基础数据请求加载
  // 访问路由是否为白名单路由 ?  ''  :  请求基础数据
  allowList.includes(to.name as string) ? '' : await AppStores().getAppDefaultBaseData()
})
