import { getInvoiceTypeList } from '@/api/system/invoiceType'
import { defineStore } from 'pinia'
import { InvoiceTypeItem } from './typing'
export const SystemInvoiceType = defineStore('SystemInvoiceType', {
  state: () => {
    return {
      list: [] as Array<InvoiceTypeItem>
    }
  },
  getters: {
    invoiceTypeList: state => state.list as Array<InvoiceTypeItem>
  },
  actions: {
    async getList() {
      try {
        const res = await getInvoiceTypeList()
        const { data, succeeded } = res
        succeeded ? (this.list = data) : ''
        return res
      } catch (error) {}
    }
  }
})
