import { PaymentTypeItem, PaymentTypeParams } from '@/stores/modules/system/typing'
import request from '@/utils/request'
import { PageResultData, RequestResult } from '../typing'

export async function getPaymentTypeList() {
  return request.get<null, RequestResult<Array<PaymentTypeItem>>>('/api/PaymentChannel/GetList')
}
export async function getPaymentTypePagedList(PaymentTypeParams: PaymentTypeParams) {
  return request.get<null, RequestResult<PageResultData<PaymentTypeItem>>>('/api/PaymentChannel/GetPagedList', { params: PaymentTypeParams })
}
