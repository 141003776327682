<template>
  <a-layout-header
    :style="{
      height: `${globalHeaderHeight}px`,
      lineHeight: `${globalHeaderHeight}px`,
      background: 'transparent'
    }"
  />
  <a-layout-header
    class="customer"
    :class="{ user: props.layout === 'user' }"
    :style="{ width: globalHeaderWidth(props.layout), height: `${globalHeaderHeight}px`, lineHeight: `${globalHeaderHeight}px` }"
  >
    <span v-if="isMobile || props.layout === 'user'" class="logo">
      <router-link :to="{ name: 'index' }">
        <img src="@/assets/logo.png" alt="logo" />
        <h1>{{ title }}</h1>
      </router-link>
    </span>
    <template v-if="!isMobile && props.layout === 'manage'">
      <svg-icon v-if="menuCollapsed" name="oa_menu-fold" style="font-size: 22px" @click="handleClick" />
      <svg-icon v-else name="oa_menu-unfold" style="font-size: 22px" @click="handleClick" />
    </template>
    <template v-if="isMobile">
      <svg-icon
        name="oa_more_line"
        :color="props.layout === 'user' ? '#fff' : ''"
        :size="24"
        @click="
          () => {
            emits('changeDrawer', true), (stores.menuCollapsed = false)
          }
        "
      />
    </template>
    <div :style="{ flex: 1 }">
      <slot name="menu" />
    </div>
    <div style="text-align: right">
      <slot name="rightContent" />
    </div>
  </a-layout-header>
</template>

<script setup lang="ts">
  import { PropType } from 'vue'
  import { storeToRefs } from 'pinia'
  import { title } from '@/config'

  import { AppStores } from '@/stores/modules/app'

  defineOptions({ name: 'GlobalHeader' })
  const stores = AppStores()
  const { isMobile, menuCollapsed, globalHeaderHeight, globalHeaderWidth } = storeToRefs(stores)

  const emits = defineEmits(['changeDrawer'])
  const props = defineProps({
    layout: {
      type: String as PropType<'manage' | 'user'>,
      default: 'manage'
    }
  })

  const handleClick = () => {
    stores.menuCollapsed = !menuCollapsed.value
  }
</script>

<style lang="less" scoped>
  .ant-layout-header.customer {
    position: fixed;
    top: 0;
    padding: 0;
    display: flex;
    align-items: center;
    padding: 0 16px;
    background: #fff;
    box-shadow: 0 1px 4px #00152914;
    z-index: 9;
    svg {
      cursor: pointer;
      margin-left: 14px;
    }
    .logo {
      line-height: 0;
      margin-right: 20px;
      img {
        height: 32px;
        display: inline-block;
      }
      h1 {
        display: inline-block;
        margin-left: 8px;
        margin-bottom: 0;
      }
    }
  }

  .ant-layout-header.customer.user {
    background-color: #001529;
    .logo {
      h1 {
        color: #fff;
      }
    }
    .ant-menu {
      width: 100%;
    }
  }
</style>
