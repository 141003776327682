---
# props

| 属性 | 说明 | 类型 | 默认值 |  |
| --- | --- | --- | --- | --- |
| placement | 弹出方向 | string | left | 弹出方向为左时,功能按钮组为水平布局,其位置为垂直布局 |
| size | 功能按钮间隔 | number | 3 |

---

<template>
  <a-popover v-bind="$attrs" :placement="props.placement" overlay-class-name="operation_button_group optbutton">
    <template #content>
      <a-space :size="spaceSize" :direction="props.placement === 'left' ? 'horizontal' : 'vertical'">
        <slot name="button" />
      </a-space>
    </template>

    <a-button type="text">
      <svg-icon name="oa_more_line" style="font-size: 18px" />
    </a-button>
  </a-popover>
</template>

<script setup lang="ts">
  import { computed } from 'vue'
  import { PropType } from 'vue'
  defineOptions({ name: 'OperationButtonGroup' })
  const props = defineProps({
    placement: {
      type: String as PropType<'top' | 'left' | 'right' | 'bottom' | 'topLeft' | 'topRight' | 'bottomLeft' | 'bottomRight' | 'leftTop' | 'leftBottom' | 'rightTop' | 'rightBotto'>,
      default: 'left'
    },
    size: {
      type: Number,
      default: 3
    }
  })
  const spaceSize = computed(() => {
    return props.placement === 'bottom' ? 3 : 0
  })
</script>
<style lang="less" scoped></style>
<style lang="less">
  .operation_button_group {
    // 向下弹出
    &.ant-popover-placement-bottom {
      padding-top: 0;
      .ant-popover-content {
        margin-top: -8px;
      }
      .ant-popover-inner {
        padding: 0;
      }
    }
    // 向左弹出
    &.ant-popover-placement-left {
      padding-right: 0;
      .ant-popover-content {
        margin-right: -14px;
      }
      .ant-popover-inner {
        padding: 0;
      }
    }
    .svg-icon {
      cursor: pointer;
      font-size: 18px;
    }
  }
</style>
