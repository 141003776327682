import request from '@/utils/request'

import { NoticeInfo, NoticeItem, NoticeParams } from '@/stores/modules/manage/administration/notice/typing'
import { RequestResult, PageResultData } from '@/api/typing'

/**
 * 获取公告列表
 * @param { NoticeParams } params
 * @returns { RequestResult<PageResultData<NoticeItem>> }
 */
export async function getNoticePageList(params: NoticeParams) {
  return request.get<NoticeParams, RequestResult<PageResultData<NoticeItem>>>('/api/notice/getPagedList', { params: params })
}

/**
 * 获取公告详情
 * @param { number } id
 * @returns { RequestResult<NoticeInfo> }
 */
export async function getNoticeInfo(id: number) {
  return request.get<number, RequestResult<NoticeInfo>>(`/api/notice/getById/${id}`)
}
