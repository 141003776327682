import request from '@/utils/request'

import { NotificationItem, NotificationParams } from '@/stores/modules/manage/administration/Notification/typing'
import { RequestResult, PageResultData } from '@/api/typing'

/**
 * 获取公告列表
 * @param { NotificationParams } params
 * @returns { RequestResult<PageResultData<NotificationItem>> }
 */
export async function getNotificationPageList(params: NotificationParams) {
  return request.get<NotificationParams, RequestResult<PageResultData<NotificationItem>>>('/api/Notification/getPagedList', { params: params })
}

/**
 * 获取公告列表
 * @param { NotificationParams } params
 * @returns { RequestResult<PageResultData<NotificationItem>> }
 */
export async function getNotificationPageListFormAdmin(params: NotificationParams) {
  return request.get<NotificationParams, RequestResult<PageResultData<NotificationItem>>>('/api/Notification/getPagedListForAdmin', { params: params })
}

/**
 * 获取公告详情
 * @param { number } id
 * @returns { RequestResult<NotificationItem> }
 */
export async function getNotificationInfo(id: number) {
  return request.get<number, RequestResult<NotificationItem>>(`/api/Notification/getById/${id}`)
}
