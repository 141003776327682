import { CaseReasonCreateOrUpdate, CaseReasonItem } from '@/stores/modules/system/typing'
import request from '@/utils/request'
import { RequestResult } from '../typing'
import { CheckExist } from '@/stores/typing'

/**
 * 获取案由Tree
 * @param {Array<number>} params
 * @returns {RequestResult<Array<CaseReasonItem>>>}
 */
export async function getCaseReasonAllTree(idList: Array<number>) {
  return request.get<Array<number>, RequestResult<Array<CaseReasonItem>>>(`/api/CaseReason/GetTreeList`, { params: { idList } })
}

/**
 * 获取案由指定节点 tree
 * @param {number} id
 * @returns {RequestResult<RegionItem>}
 */
export async function getCaseReasonNodeTree(id: number) {
  return request.get<number, RequestResult<CaseReasonItem>>(`/api/CaseReason/GetTree/${id}`)
}

/**
 * 获取案由节点ID下子节点
 * @param {number} id
 * @returns {RequestResult<Array<CaseReasonItem>>}
 */
export async function getCaseReasonChildList(id: number) {
  return request.get<number, RequestResult<Array<CaseReasonItem>>>(`/api/CaseReason/GetChildList/${id}`)
}

/**
 * 根据Id 获取案由详情
 * @param {number} id
 * @returns {RequestResult<CaseReasonItem>}
 */
export async function getCaseReasonInfo(id: number) {
  return request.get<number, RequestResult<CaseReasonItem>>(`/api/CaseReason/GetById/${id}`)
}

/**
 * 检查是否重复
 * @param {CheckExist} CheckExist
 * @returns {RequestResult<boolean>}
 */
export async function checkCaseReasonName(CheckExist: CheckExist) {
  return request.post<CheckExist, RequestResult<boolean>>(`/api/CaseReason/CheckExist`, CheckExist)
}

/**
 * 添加案由
 * @param {CaseReasonCreateOrUpdate} caseReason
 * @returns {RequestResult<null>}
 */
export async function createCaseReason(caseReason: CaseReasonCreateOrUpdate) {
  return request.post<CaseReasonCreateOrUpdate, RequestResult<null>>('/api/CaseReason/Create', caseReason)
}

/**
 * 编辑案由
 * @param {CaseReasonCreateOrUpdate} caseReason
 * @returns {RequestResult<null>}
 */
export async function updateCaseReason(caseReason: CaseReasonCreateOrUpdate) {
  return request.put<CaseReasonCreateOrUpdate, RequestResult<null>>('/api/CaseReason/Update', caseReason)
}

/**
 * 删除案由
 * @param {number} id
 * @returns {RequestResult<null>}
 */
export async function deleteCaseReason(id: number) {
  return request.delete<number, RequestResult<null>>(`/api/CaseReason/Delete/${id}`)
}

/**
 * 软删除案由
 * @param {number} id
 * @returns {RequestResult<null>}
 */
export async function deleteCaseReasonSoft(id: number) {
  return request.post<number, RequestResult<null>>(`/api/CaseReason/SoftDelete/${id}`)
}
