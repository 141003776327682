<template>
  <div :class="`${props.prefixCls}-detail`">
    <div :class="`${props.prefixCls}-main`">
      <div :class="`${props.prefixCls}-row`">
        <div v-if="$slots.content" :class="`${props.prefixCls}-content`">
          <slot name="content" />
        </div>
        <div v-if="$slots.extraContent" :class="`${props.prefixCls}-extraContent`">
          <slot name="extraContent" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  defineOptions({ name: 'PageHeaderContent' })

  const props = defineProps({
    prefixCls: {
      type: String,
      default: 'jingshonline'
    }
  })
</script>
