// 检查 VConsole 是否启用
import VConsole from 'vconsole'

import { allowShowConsoleEnv } from '@/config'

export const showVConsole = () => {
  if (allowShowConsoleEnv.includes(window.location.hostname)) {
    new VConsole()
  }
}
