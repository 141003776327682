import { allowBroswerType, allowBroswerTypeNoVersion, allowBroswerTypeVersion } from '@/config'

/**
 * 判断为移动端或PC端
 * @returns {string}
 */
export const isMobile = (): string => {
  if (navigator.userAgent.match(/(iPhone|iPod|Android|ios|iOS|iPad|Backerry|WebOS|Symbian|Windows Phone|Phone)/i)) {
    return 'mobile'
  }
  return 'desktop'
}

/**
 * 判断是否是苹果设备
 * @returns {boolean}
 */
export const isAppleMobileDevice = (): boolean => {
  const reg = /iphone|ipod|ipad|Macintosh/i
  return reg.test(navigator.userAgent.toLowerCase())
}

/**
 * 判断是否是安卓移动设备
 * @returns {boolean}
 */
export const isAndroidMobileDevice = (): boolean => {
  return /android/i.test(navigator.userAgent.toLowerCase())
}

/**
 * 判断是Windows还是Mac系统
 * @returns {string}
 */
export const osType = (): void | string => {
  const agent = navigator.userAgent.toLowerCase()
  const isMac = /macintosh|mac os x/i.test(navigator.userAgent)
  const isWindows = agent.indexOf('win64') >= 0 || agent.indexOf('wow64') >= 0 || agent.indexOf('win32') >= 0 || agent.indexOf('wow32') >= 0
  if (isWindows) {
    return 'windows'
  }
  if (isMac) {
    return 'mac'
  }
}
/**
 * 判断是否是微信/QQ内置浏览器
 * @returns {boolean}
 */
export const broswer = (): void | boolean | string => {
  const t = navigator.userAgent.toLowerCase() || ''

  const ua = t.match(/(micromessenger|alipayclient|qq)\/([\d]+)/)
  if (ua !== null) {
    return ua[1]
  }
  return false
}

/**
 * 获取可视窗口高度
 * @returns {number}
 */
export const getClientHeight = (): number => {
  let clientHeight = 0
  if (document.body.clientHeight && document.documentElement.clientHeight) {
    clientHeight = document.body.clientHeight < document.documentElement.clientHeight ? document.body.clientHeight : document.documentElement.clientHeight
  } else {
    clientHeight = document.body.clientHeight > document.documentElement.clientHeight ? document.body.clientHeight : document.documentElement.clientHeight
  }
  return clientHeight
}

/**
 * 获取可视窗口宽度
 * @returns {number}
 */
export const getPageViewWidth = (): number => {
  return (document.compatMode == 'BackCompat' ? document.body : document.documentElement).clientWidth
}

/**
 * 浏览器型号和版本
 * @returns { type: string; version: number }
 */
export const getExplorerInfo = (): { type: string; version: number } => {
  const t = navigator.userAgent.toLowerCase() || ''
  const ua = t.match(/(msie|firefox|chrome|opera|safari|micromessenger|alipayclient)\/([\d]+)/)
  const browser = ua !== null ? { type: ua[1], version: Number(ua[2]) } : { type: 'other', version: 0 }
  return browser
}

export const checkBrosewer = (minVersion = allowBroswerTypeVersion): boolean => {
  const { type, version } = getExplorerInfo()
  console.log(
    `浏览器内核: ${type}, 内核版本: ${version}, 系统类型: ${osType()} , QQ/weixin/支付宝等内置浏览器: ${broswer()}, 苹果移动设备: ${isAppleMobileDevice()}, 安卓移动设备: ${isAndroidMobileDevice()}`
  )
  const allow = (allowBroswerType.includes(type) && version >= minVersion) || allowBroswerTypeNoVersion.includes(type)
  allow ? '' : alert('您的浏览器不受支持或版本过低 , 请使用其他浏览器或升级浏览器版本')
  console.log(`是否允许: ${allow},允许的浏览器类型(需版本号): ${allowBroswerType}, 允许的浏览器类型(不包含版本号): ${allowBroswerTypeNoVersion},版本号: ${minVersion}`)
  return allow
}
