import { storeToRefs } from 'pinia'
import { BindingMetadata } from '@vue/compiler-core'
import pinia from '@/stores/index'
import { Permission } from '@/stores/modules/permission'
import { enablePermission } from '@/config'
const store = Permission(pinia)

/**
 * 删除节点
 * @param { Element } el 节点
 * @returns
 */
const remove = (el: Element) => (el.parentNode !== null ? el.parentNode.removeChild(el) : '')

type Binding = {
  value: string | Array<string>
  modifiers: { some: boolean; every: boolean; [key: string]: any }
} & BindingMetadata

/**
 * 认证\确权
 * @param { Element } el
 * @param binding
 */

export const auth = {
  mounted: (el: Element, binding: Binding) => {
    if (enablePermission && binding.value) {
      const { userRole: role } = storeToRefs(store)
      const { value } = binding

      const valType = typeof value

      const {
        modifiers: { some, every }
      } = binding
      // 功能需要权限 :权限集不为空 且 单一权限 且 无相关修饰符 ? 显示 : 隐藏
      if (role.value.length && valType === 'string' && !some && !every) {
        return role.value.includes(value as string) ? '' : remove(el)
      }

      /**
       * 判断条件
       *  -修饰符为 every时 value数组只要有一个元素不存在 role 权限集内，隐藏元素
       *  -修饰符为 some或者没有时，value数组所有元素都不存在 role 权限集内，隐藏元素
       */

      // 功能需要权限 :权限集不为空 且 多个权限 且 some修饰符 且 多个权限满足其一 ? 显示 : 隐藏
      if (role.value.length && valType !== 'string' && some && !every) {
        return (value as Array<string>).some((item: string) => role.value.includes(item)) ? '' : remove(el)
      }

      // 功能需要权限 :权限集不为空 且 多个权限 且 every修饰符 且 需同时满足多个权限 ? 显示 : 隐藏
      if (role.value.length && valType !== 'string' && !some && every) {
        return (value as Array<string>).every((item: string) => role.value.includes(item)) ? '' : remove(el)
      }
      remove(el)
    }
  }
}
