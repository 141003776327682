import { createApp } from 'vue'

import App from './App.vue'
import Brosewer from './Browser.vue'

import { TransformVnode } from '@/components'
import PageContainer from '@/components/pageContainer/index.vue' // 页头(内含面包屑导航)
import SvgIcon from '@/components/svgIcon/index.vue' // svgicon组件
import TableListLayout from '@/components/layout/tableListLayout.vue' // 列表布局组件
import OperationButtonGroup from '@/components/operationButtonGroup/index.vue' // 操作按钮组组件
import PageIndex from '@/components/pagination/index.vue' // 分页组件

// 路由拦截器
import router from './router'
import './router/routerGuards'

// store 存储
import pinia from './stores/index'

// 时间组件 & 启用 utc 插件
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import quarterOfYear from 'dayjs/plugin/quarterOfYear'
import 'dayjs/locale/zh-cn'
dayjs.extend(utc)
dayjs.extend(quarterOfYear)
dayjs.locale('zh-cn')

// 自定义指令 权限控制
import { auth } from './directive/auth'

// UI组件 Ant-design-vue
import Antd from 'ant-design-vue'
import 'ant-design-vue/dist/reset.css'

// 检查浏览器版本
import { checkBrosewer } from './utils/broswer'
import { getTiming } from './utils/firstScreenTime'

// VConsole 输出调试
import { showVConsole } from './utils/vConsole'
showVConsole()

// 全局样式
import './app.less'
// print 打印 样式
import 'print-js/dist/print.css'

// 创建 app vue 实例
const upgrade = createApp(Brosewer)
const app = createApp(App)

// app 实例挂载 router(vue-router)  pinia(pinia) antd(ant-design-vue),注册全局组件 pageContainer(page-container) transformVnode(transform-vnode) svgIcon(svg-icon)  PageIndex(Page\Pageination) tableListLayout(table-list-layout) operationButtonGroup(operation-button-group)
app
  .use(router)
  .use(pinia)
  .use(Antd)
  .component(TransformVnode.name, TransformVnode)
  .component(PageContainer.name, PageContainer)
  .component(SvgIcon.name, SvgIcon)
  .component('Page', PageIndex)
  .component('Pageination', PageIndex)
  .component(TableListLayout.name, TableListLayout)
  .component(OperationButtonGroup.name, OperationButtonGroup)

// app 实例挂载 自定义指令 auth
app.directive('auth', auth)

window.addEventListener('load', getTiming, false)
// 检查浏览器是否受支持,根据检查结果进行不同实例进行渲染
checkBrosewer() ? app.mount('#app') : upgrade.mount('#app')
