import request from '@/utils/request'
import { PageResultData, RequestResult, QueryParams } from '../typing'
import { ContractSourceCreateOrUpdate, ContractSourceItem } from '@/stores/modules/system/typing'
import { CheckExist } from '@/stores/typing'

export type ContractSourceParams = {
  name?: string
} & QueryParams

/**
 * 获取当前用户所在律所案件来源
 * @returns {RequestResult<PageResultData<ContractSourceItem>>}
 */
export async function getContractSource() {
  return request.get<null, RequestResult<Array<ContractSourceItem>>>('/api/ContractSource/getList')
}

/**
 * 案件来源
 * 获取案件来源列表
 * @param {ContractSourceParams} ContractSourceParams
 * @returns {PageResultData<ContractSourceItem>}
 */
export async function getContractSourceList(ContractSourceParams: ContractSourceParams) {
  return request.get<ContractSourceParams, RequestResult<PageResultData<ContractSourceItem>>>('/api/ContractSource/GetPagedList', { params: ContractSourceParams })
}

/**
 * 根据ID获取案件来源详情
 * @param {number} id
 * @returns {RequestResult<ContractSourceItem>}
 */
export async function getContractSourceInfo(id: number) {
  return request.get<number, RequestResult<ContractSourceItem>>(`/api/ContractSource/GetById/${id}`)
}

/**
 * 检查是否重复
 * @param {CheckExist} CheckExist
 * @returns {RequestResult<ContractSourceItem>}
 */
export async function checkContractSourceName(CheckExist: CheckExist) {
  return request.post<CheckExist, RequestResult<boolean>>(`/api/ContractSource/CheckExist`, CheckExist)
}

/**
 * 创建案件来源
 * @param {ContractSourceCreateOrUpdate} data
 * @returns {RequestResult}
 */
export async function createContractSource(data: ContractSourceCreateOrUpdate) {
  return request.post<ContractSourceCreateOrUpdate, RequestResult<null>>(`/api/ContractSource/create`, data)
}

/**
 * 编辑案件来源
 * @param {ContractSourceCreateOrUpdate} data
 * @returns {RequestResult}
 */
export async function updateContractSource(data: ContractSourceCreateOrUpdate) {
  return request.put<ContractSourceCreateOrUpdate, RequestResult<null>>(`/api/ContractSource/update`, data)
}

/**
 * 删除案件来源
 * @param {ContractSourceItem} data
 * @returns {RequestResult}
 */
export async function deleteContractSource(id: number) {
  return request.delete<number, RequestResult<null>>(`/api/ContractSource/delete/${id}`)
}

/**
 * 软删除案件来源
 * @param {ContractSourceItem} data
 * @returns {RequestResult}
 */
export async function deleteContractSourceSoft(id: number) {
  return request.post<number, RequestResult<null>>(`/api/ContractSource/SoftDelete/${id}`)
}
