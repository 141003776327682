import Cookies, { CookieAttributes } from 'js-cookie'
import { cookiePrefix, storage } from '@/config/index'

/**
 * 读取
 * @param {string} name 名称 key
 * @returns {string | undefined | null}
 */
export const get = (name: string): string | undefined | null => {
  switch (storage) {
    case 'localStorage':
      return localStorage.getItem(`${cookiePrefix}-${name}`)
    case 'sessionStorage':
      return sessionStorage.getItem(`${cookiePrefix}-${name}`)
    case 'cookies':
      return Cookies.get(`${cookiePrefix}-${name}`)
    default:
      return Cookies.get(`${cookiePrefix}-${name}`)
  }
}

/**
 * 存储
 * @param {string} name 存储名称 key
 * @param {string} value 存储值 value
 * @param {CookieAttributes | undefined} option 其他选项 Cookiess 使用
 * @returns {string|undefined|void}
 */
export const set = (name: string, value: string, option?: CookieAttributes | undefined): string | undefined | void => {
  if (value) {
    switch (storage) {
      case 'localStorage':
        return localStorage.setItem(`${cookiePrefix}-${name}`, value)
      case 'sessionStorage':
        return sessionStorage.setItem(`${cookiePrefix}-${name}`, value)
      case 'cookies':
        return Cookies.set(`${cookiePrefix}-${name}`, value, { ...option, SameSite: 'Lax' })
      default:
        return Cookies.set(`${cookiePrefix}-${name}`, value, { ...option, SameSite: 'Lax' })
    }
  } else {
    remove(name)
  }
}

/**
 * 移除
 * @param {string} name 名称 key
 * @param {CookieAttributes|undefined} option 其他选项 Cookiess 使用
 * @returns {void}
 */
export const remove = (name: string, option?: CookieAttributes | undefined): void => {
  switch (storage) {
    case 'localStorage':
      return localStorage.removeItem(`${cookiePrefix}-${name}`)
    case 'sessionStorage':
      return sessionStorage.removeItem(`${cookiePrefix}-${name}`)
    case 'cookies':
      return Cookies.remove(`${cookiePrefix}-${name}`, option || { expires: -1, path: '/' })
    default:
      return Cookies.remove(`${cookiePrefix}-${name}`, option || { expires: -1, path: '/' })
  }
}

const clearAllCookie = () => {
  const keys = document.cookie.match(/[^ =;]+(?=)/g)
  keys?.forEach(item => {
    Cookies.remove(`${item}`, { expires: -1, path: '/' })
  })
}

/**
 * 移除全部
 * @returns {void}
 */
export const removeAll = (): void => {
  switch (storage) {
    case 'localStorage':
      localStorage.clear()
      return
    case 'sessionStorage':
      sessionStorage.clear()
      return
    case 'cookies':
      clearAllCookie()
      return
    default:
      clearAllCookie()
      return
  }
}

export const cookie = { get, set, remove, removeAll }
