import { InvoiceTypeItem, InvoiceTypeParam } from '@/stores/modules/system/typing'
import request from '@/utils/request'
import { RequestResult } from '../typing'

export async function getInvoiceTypeList() {
  return request.get<null, RequestResult<Array<InvoiceTypeItem>>>('/api/InvoiceType/GetList')
}
/**
 * 用在开票页面
 * @param {InvoiceTypeParam} params
 */

export async function getInvoiceTypeListfilter(params: InvoiceTypeParam) {
  return request.get<null, RequestResult<Array<InvoiceTypeItem>>>('/api/InvoiceType/GetList', { params: params })
}
