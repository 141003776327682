import { Component } from 'vue'

import { MenuDataItem } from '@/router/typing'
import RouteView from '@/layouts/routeView.vue'

export const Case: MenuDataItem = {
  path: 'case',
  name: 'manage-case',
  meta: { title: '案件管理', role: 'Admin.Case', icon: 'oa_yewuguanli', fullPath: '/manage/case' },
  component: RouteView,
  redirect: '/manage/case/index',
  children: [
    {
      path: 'check',
      name: 'manage-case-check',
      meta: { icon: 'oa_confirm', title: '案件预检', fullPath: '/manage/case/check' },
      component: (): Component => import('@/views/manage/case/check.vue')
    },

    {
      path: 'index',
      name: 'manage-case-index',
      meta: { icon: 'oa_yewuguanli', role: 'Admin.Case.Manage', title: '案件管理', fullPath: '/manage/case/index' },
      component: (): Component => import('@/views/manage/case/index.vue')
    },
    {
      path: 'info/:contractId(\\d+)',
      name: 'manage-case-info',
      meta: { icon: 'oa_show', role: 'Admin.Case.Manage.Show', hideInMenu: true, title: '案件详情', fullPath: '/manage/case/info' },
      component: (): Component => import('@/views/manage/case/info.vue')
    },
    {
      path: 'update/:contractId(\\d+)',
      name: 'manage-case-update',
      meta: { icon: 'oa_update', role: 'Admin.Case.Manage.Update', hideInMenu: true, title: '案件编辑', fullPath: '/manage/case/update' },
      component: (): Component => import('@/views/manage/case/update.vue')
    },
    {
      path: 'delete/:contractId(\\d+)+',
      name: 'manage-case-delete',
      meta: { icon: 'oa_delete', role: 'Admin.Case.Manage.Delete', hideInMenu: true, title: '案件删除', fullPath: '/manage/case/delete' },
      component: (): Component => import('@/views/manage/case/delete.vue')
    },
    {
      path: 'status/:contractId(\\d+)+',
      name: 'manage-case-status',
      meta: { icon: 'oa_reload', role: 'Admin.Case.Manage.Update', hideInMenu: true, title: '案件状态修改', fullPath: '/manage/case/status' },
      component: (): Component => import('@/views/manage/case/status.vue')
    },
    {
      path: 'complaint/:contractId(\\d+)',
      name: 'manage-case-complaint',
      meta: { icon: 'oa_tousu', role: 'Admin.Complaint.Business', hideInMenu: true, title: '案件投诉登记', fullPath: '/manage/case/complaint' },
      component: (): Component => import('@/views/manage/complaint/business/createOrUpdate.vue')
    },
    {
      path: 'passList',
      name: 'manage-case-pass-list',
      meta: { icon: 'oa_pass', role: 'Admin.Case.Pass', title: '风控检查', fullPath: '/manage/case/passlist' },
      component: (): Component => import('@/views/manage/case/pass/index.vue')
    },
    {
      path: 'pass/:contractId(\\d+)',
      name: 'manage-case-pass',
      meta: { icon: 'oa_pass', role: 'Admin.Case.Pass', hideInMenu: true, title: '风控检查', fullPath: '/manage/case/pass' },
      component: (): Component => import('@/views/manage/case/pass/pass.vue')
    },
    {
      path: 'signList',
      name: 'manage-case-sign-list',
      meta: { icon: 'oa_sign', role: 'Admin.Case.Sign', title: '合同盖章', fullPath: '/manage/case/signlist' },
      component: (): Component => import('@/views/manage/case/sign/index.vue')
    },
    {
      path: 'sign/:contractId(\\d+)',
      name: 'manage-case-sign-info',
      meta: { icon: 'oa_sign', role: 'Admin.Case.Sign', hideInMenu: true, title: '合同盖章', fullPath: '/manage/case/sign' },
      component: (): Component => import('@/views/manage/case/sign/sign.vue')
    },
    {
      path: 'contractArchivingList',
      name: 'manage-case-contract-archiving-list',
      meta: { icon: 'oa_guidang', role: 'Admin.Case.Sign', title: '案件合同归档', fullPath: '/manage/case/contractarchivinglist' },
      component: (): Component => import('@/views/manage/case/contractArchiving/index.vue')
    },
    {
      path: 'contractArchiving/:contractId(\\d+)',
      name: 'manage-case-contract-archiving',
      meta: { icon: 'oa_guidang', role: 'Admin.Case.Sign', hideInMenu: true, title: '案件合同归档', fullPath: '/manage/case/contractarchiving' },
      component: (): Component => import('@/views/manage/case/contractArchiving/contractArchiving.vue')
    },
    {
      path: 'supplementlist',
      name: 'manage-case-supplementlist',
      meta: { icon: 'oa_file', role: 'Admin.Case.Files', title: '案件补充材料管理', fullPath: '/manage/case/supplementlist' },
      component: (): Component => import('@/views/manage/case/supplement/index.vue')
    },
    {
      path: 'supplement/:contractId(\\d+)',
      name: 'manage-case-supplement',
      meta: { icon: 'oa_file', role: 'Admin.Case.Files', hideInMenu: true, title: '案件补充材料', fullPath: '/manage/case/supplement' },
      component: (): Component => import('@/views/manage/case/supplement/supplement.vue')
    },
    {
      path: 'endList',
      name: 'manage-case-end-list',
      meta: { icon: 'oa_end', role: 'Admin.Case.EndCase', title: '结案管理', fullPath: '/manage/case/endlist' },
      component: (): Component => import('@/views/manage/case/end/index.vue')
    },
    {
      path: 'end/:contractId(\\d+)',
      name: 'manage-case-end-info',
      meta: { icon: 'oa_end', role: 'Admin.Case.EndCase', hideInMenu: true, title: '案件结案', fullPath: '/manage/case/end' },
      component: (): Component => import('@/views/manage/case/end/end.vue')
    },
    {
      path: 'archivedList',
      name: 'manage-case-archived-list',
      meta: { icon: 'oa_guidang', role: 'Admin.Case.Archive', title: '案件归档管理', fullPath: '/manage/case/archivedlist' },
      component: (): Component => import('@/views/manage/case/archived/index.vue')
    },
    {
      path: 'archived/:contractId(\\d+)',
      name: 'manage-case-archived-info',
      meta: { icon: 'oa_guidang', role: 'Admin.Case.Archive', hideInMenu: true, title: '案件归档', fullPath: '/manage/case/archived' },
      component: (): Component => import('@/views/manage/case/archived/archived.vue')
    },
    {
      path: 'leavelist',
      name: 'manage-case-leavelist',
      meta: { icon: 'oa_export', role: 'Admin.Case.Leave', title: '案件转出管理', fullPath: '/manage/case/leavelist' },
      component: (): Component => import('@/views/manage/case/leave/index.vue')
    },
    {
      path: 'leave/:contractId(\\d+)',
      name: 'manage-case-leave',
      meta: { icon: 'oa_export', role: 'Admin.Case.Leave', hideInMenu: true, title: '四方协议转出', fullPath: '/manage/case/leave' },
      component: (): Component => import('@/views/manage/case/leave/leave.vue')
    },
    {
      path: 'relievelist',
      name: 'manage-case-relievelist',
      meta: { icon: 'oa_jiechu', role: 'Admin.Case.Relieve', title: '案件解除管理', fullPath: '/manage/case/relievelist' },
      component: (): Component => import('@/views/manage/case/relieve/index.vue')
    },
    {
      path: 'relieve/:contractId(\\d+)',
      name: 'manage-case-relieve',
      meta: { icon: 'oa_jiechu', role: 'Admin.Case.Relieve', hideInMenu: true, title: '合同解除', fullPath: '/manage/case/relieve' },
      component: (): Component => import('@/views/manage/case/relieve/relieve.vue')
    },
    {
      path: 'transferlist',
      name: 'manage-case-transferlist',
      meta: { icon: 'oa_tongbu', role: 'Admin.Case.Transfer', title: '案件转移管理', fullPath: '/manage/case/transferlist' },
      component: (): Component => import('@/views/manage/case/transfer/index.vue')
    },
    {
      path: 'transfer/:contractId(\\d+)+',
      props: true,
      name: 'manage-case-transfer',
      meta: { icon: 'oa_tongbu', role: 'Admin.Case.Transfer', hideInMenu: true, title: '案件转移', fullPath: '/manage/case/transfer' },
      component: (): Component => import('@/views/manage/case/transfer/transfer.vue')
    }
  ]
}
