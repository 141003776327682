import { defineStore } from 'pinia'
export const ThemeStores = defineStore('ThemeStores', {
  state: () => {
    return {
      token: {}
    }
  },
  getters: {},
  actions: {}
})
