---

# defineProps

| 属性 | 说明 | 类型 | 默认值 | 补充说明 |
| --- | --- | --- | --- | --- |
| showTitle | 是否显示默认Title | Boolean | true |  |
| create | 添加功能 | Boolean | false | 需配合 emit create 使用 |
| search | 搜索功能 | Boolean | false | 需配合 emit search \ searchReset 使用 |
| export | 导出功能 | Boolean | false | 需配合 emit export 使用 |
| exportOption | 是否需要导出项 | Boolean | true | 需配合 props.export 使用 |
| tableHeader | 自定义表头 | Boolean | false | 需配合 props.tableColumns  使用 |
| tableColumns | table 表头列定义 | TableColumns[] | [] | 配合 props.tableHeader \ emit setHeaderColumns 使用 |
| createRole | 添加功能权限 | String | '' | 配合 props.create 使用, 启用添加但需要权限判断时传入 |

# emit

| 方法 | 说明 | 类型 | 默认值 | 补充说明 |
| --- | --- | --- | --- | --- |
| create | 添加 | function |  | 配合 props.create |
| search | 搜索 | function |  | 配合 props.search |
| searchReset | 重置搜索 | function |  | 配合 props.search |
| setHeaderColumns | 设置自定义表头 | function (string[]) tableHeaderColumns keyIndex | 配合 props.tableHeader \ props.tableColumns 使用 |

# slot

| 属性 | 说明 | 类型 | 默认值 |
| --- | --- | --- | --- |
| title | 标题 | slot | $route.meta.title |
| search | 搜索条件 | slot |  |
| export | 导出插槽,导出条件配置 | slot |  |
| table | 表格显示区 | slot |  |
| page | 分页功能区 | slot |  |
| operated | 自定义功能区 | slot |  |

---

<template>
  <a-card v-bind="$attrs" id="cardbody">
    <template #title>
      <div class="list_top">
        <div class="title">
          <template v-if="props.showTitle">
            <template v-if="!$slots.title">
              <div>
                <svg-icon :name="route.meta.icon" />
                {{ route.meta.title }}
              </div>
            </template>
            <template v-else>
              <slot name="title" />
            </template>
          </template>
        </div>
        <div class="operated">
          <a-space :size="14" :align="'baseline'">
            <a-button v-if="props.create" v-auth="props.createRole" type="text" title="添加" class="create" @click="emit('create')">
              <template #icon>
                <svg-icon name="oa_plus" />
              </template>
            </a-button>
            <!--search start-->
            <template v-if="$slots.search">
              <a-button v-auth="props.searchRole" type="text" title="搜索" @click="state.searchDrawerVisible = !state.searchDrawerVisible">
                <template #icon>
                  <svg-icon name="oa_search" />
                </template>
              </a-button>
            </template>
            <!--search end-->
            <template v-if="$slots.operated">
              <slot name="operated" />
            </template>
            <!--export start-->
            <template v-if="props.export">
              <a-button v-auth="props.exportRole" type="text" title="导出" @click="state.exportDrawerVisible = !state.exportDrawerVisible">
                <template #icon>
                  <svg-icon name="oa_export" />
                </template>
              </a-button>
            </template>
            <!--export end-->

            <!--table header columns start-->
            <template v-if="props.tableHeader">
              <div title="列展示" @click="state.settingDrawerVisible = !state.settingDrawerVisible">
                <a-button type="text">
                  <template #icon>
                    <svg-icon name="oa_setting" />
                  </template>
                </a-button>
              </div>
            </template>
            <!--table header columns end-->
          </a-space>

          <!--search drawer start-->
          <a-drawer
            placement="right"
            title="查询条件"
            :width="500"
            :closable="false"
            :open="state.searchDrawerVisible"
            get-container="#cardbody"
            :root-style="{ position: 'absolute' }"
            @close="state.searchDrawerVisible = !state.searchDrawerVisible"
          >
            <a-form :label-col="{ span: 5 }" :wrapper-col="{ offset: 1 }">
              <slot name="search" />
              <a-form-item style="text-align: center">
                <a-space :size="24">
                  <a-button
                    @click="
                      () => {
                        emit('searchReset'), (state.searchDrawerVisible = !state.searchDrawerVisible)
                      }
                    "
                  >
                    <svg-icon name="oa_reload" />重置
                  </a-button>
                  <a-button
                    type="primary"
                    html-type="submit"
                    @click="
                      () => {
                        emit('search'), (state.searchDrawerVisible = !state.searchDrawerVisible)
                      }
                    "
                  >
                    <svg-icon name="oa_search" />搜索
                  </a-button>
                </a-space>
              </a-form-item>
            </a-form>
          </a-drawer>
          <!--search end-->

          <!--export drawer start-->
          <a-drawer
            placement="right"
            title="数据导出选项"
            :width="500"
            :closable="false"
            :open="state.exportDrawerVisible"
            get-container="#cardbody"
            :root-style="{ position: 'absolute' }"
            @close="state.exportDrawerVisible = !state.exportDrawerVisible"
          >
            <a-tag color="orange" style="padding: 8px; display: block; margin-bottom: 20px">
              进行数据导出时可在根据需要进行检索之后进行导出 <br />
              导出时可对数据展示项进行选择
            </a-tag>
            <a-form layout="vertical">
              <slot name="export" />
              <a-form-item label="文件名">
                <a-input v-model:value="state.exportFileName" placeholder="导出文件名" style="margin-bottom: 20px" />
              </a-form-item>
              <!-- 导出插槽 -->
              <a-form-item v-if="props.exportOption && props.tableColumns.length" label="导出项">
                <a-checkbox-group v-model:value="state.exportFields">
                  <a-row :gutter="[8, 24]">
                    <a-col v-for="(item, index) in props.tableColumns" :key="index" :span="8">
                      <template v-if="!item.exportHide">
                        <a-checkbox :value="item.key" :disabled="item.exportDisabled"> {{ item.title }}</a-checkbox>
                      </template>
                    </a-col>
                  </a-row>
                </a-checkbox-group>
              </a-form-item>
              <a-form-item>
                <a-button
                  type="primary"
                  shape="round"
                  style="width: 100%"
                  @click="
                    () => {
                      emit('export', { fileName: state.exportFileName, fields: state.exportFields }), (state.exportDrawerVisible = !state.exportDrawerVisible)
                    }
                  "
                >
                  导出
                </a-button>
              </a-form-item>
            </a-form>
          </a-drawer>
          <!--export drawer end-->

          <!--table header columns drawer start-->
          <a-drawer
            placement="right"
            title="展示列设置"
            :width="500"
            :closable="false"
            :open="state.settingDrawerVisible"
            get-container="#cardbody"
            :root-style="{ position: 'absolute' }"
            @close="state.settingDrawerVisible = !state.settingDrawerVisible"
          >
            <a-tag color="orange" style="padding: 8px; display: block; margin-bottom: 20px"> 可选择相应项对表格展示进行调整 </a-tag>
            <a-checkbox-group v-model:value="state.tableHeaderColumns">
              <a-row :gutter="[8, 24]">
                <a-col v-for="(item, index) in props.tableColumns" :key="index" :span="8">
                  <template v-if="!item.columnsHide">
                    <a-checkbox :value="item.key" :disabled="item.columnsDisabled"> {{ item.title }}</a-checkbox>
                  </template>
                </a-col>
              </a-row>
            </a-checkbox-group>
            <div style="margin-top: 30px">
              <a-button
                type="primary"
                shape="round"
                style="width: 100%"
                @click="
                  () => {
                    saveColumns(), emit('setHeaderColumns', state.tableHeaderColumns), (state.settingDrawerVisible = !state.settingDrawerVisible)
                  }
                "
              >
                保存
              </a-button>
            </div>
          </a-drawer>
          <!--table header columns drawer end-->
        </div>
      </div>
    </template>
    <slot name="table" />
  </a-card>
  <div>
    <slot name="page" />
  </div>
</template>
<script setup lang="ts">
  import { TableColumns } from '@/stores/typing'
  import { cookie } from '@/utils/cookies'
  import { PropType, reactive, watch } from 'vue'
  import { useRoute } from 'vue-router'
  defineOptions({ name: 'TableListLayout' })
  const route = useRoute()
  const props = defineProps({
    // 是否显示默认Title
    showTitle: {
      type: Boolean,
      default: true
    },
    // 是否需要添加
    create: {
      type: Boolean,
      default: false
    },
    // 是否需要搜索
    search: {
      type: Boolean,
      default: false
    },
    // 是否需要导出
    export: {
      type: Boolean,
      default: false
    },
    exportOption: {
      type: Boolean,
      default: true
    },
    // 是否需要 表头设置
    tableHeader: {
      type: Boolean,
      default: false
    },
    // 表头列
    tableColumns: {
      type: Array as PropType<Array<TableColumns>>,
      default: () => []
    },
    createRole: {
      type: String
    },
    searchRole: {
      type: String
    },
    exportRole: {
      type: String
    }
  })
  const emit = defineEmits(['search', 'searchReset', 'create', 'export', 'setHeaderColumns'])

  const state = reactive({
    searchDrawerVisible: false, // 搜索
    exportDrawerVisible: false, // 导出
    settingDrawerVisible: false, // 设置 表头列
    exportFileName: '导出数据',
    exportFields: [],
    tableHeaderColumns: []
  }) as {
    searchDrawerVisible: boolean // 搜索
    exportDrawerVisible: boolean // 导出
    settingDrawerVisible: boolean // 设置
    exportFileName: string // 导出文件名
    exportFields: Array<string> // 导出项
    tableHeaderColumns: Array<string> // 表头
    [key: string]: any
  }
  watch(
    () => props.tableColumns,
    () => {
      props.tableColumns.forEach(item => {
        item.columnsSelected ? state.tableHeaderColumns.push(item.key) : ''
        item.exportSelected ? state.exportFields.push(item.key) : ''
      })
    },
    {
      immediate: true
    }
  )
  watch(
    () => route,
    () => {
      const cacheKey = cookie.get(route.meta.fullPath as string)?.split(',')
      cacheKey ? ((state.tableHeaderColumns = []), cacheKey?.forEach(item => state.tableHeaderColumns.push(item))) : ''
      emit('setHeaderColumns', state.tableHeaderColumns)
    },
    {
      immediate: true
    }
  )

  const saveColumns = () => {
    cookie.set(route.meta.fullPath as string, state.tableHeaderColumns.toString())
  }
</script>
<style lang="less" scoped>
  /** table list layout */
  .ant-form-item {
    margin-bottom: 8px;
  }

  .ant-card {
    background: initial;
    border: none;
    :deep(.ant-card-head) {
      padding: 0 14px;
      background: #fff;
      border: 1px solid #f0f0f0;
    }
    :deep(.ant-card-body) {
      padding: 0;
      min-height: 74vh;
    }
  }

  #cardbody {
    overflow: hidden;
  }
  .list_top {
    display: flex;
    justify-content: space-between;
    .title {
      display: flex;
      justify-content: flex-start;
      font-size: 16px;
      font-weight: bold;
      line-height: 32px;
      svg {
        font-size: 20px;
        margin-right: 4px;
      }
    }
    .operated {
      display: flex;
      justify-content: flex-end;
      overflow: hidden;
      :deep(svg) {
        font-size: 20px;
        &:hover {
          color: @colorPrimaryActive;
        }
      }
      .create {
        color: @colorSuccess;
      }
    }
    :deep(.ant-space-item) {
      cursor: pointer;
    }
  }
</style>
