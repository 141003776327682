// 允许的浏览器类型(需版本检查)
export const allowBroswerType: string[] = ['firefox', 'safari', 'chrome', 'opera']
export const allowBroswerTypeVersion = 80
// 允许的浏览器类型(无需版本检查)
export const allowBroswerTypeNoVersion: string[] = ['micromessenger', 'alipayclient']
// 允许显示 VConsole 输出 的环境
export const allowShowConsoleEnv = ['test.oa.jingshonline.net', 'localhost']

// 存储类型(位置)
export type StorageType = 'sessionStorage' | 'cookies' | 'localStorage'
// 标题 (包括初次加载雪花屏的标题 页面的标题 浏览器的标题)
export const title = '律所OA系统'
// 标题分隔符
export const titleSeparator = ' - '
// 标题是否反转 如果为false:"page - title"，如果为ture:"title - page"
export const titleReverse = false
// 开发环境端口号
export const devPort = 9997
// cookie 前缀
export const cookiePrefix = 'OASystem'
// token 请求头前缀
export const tokenPrefix = 'Bearer '
// token名称 axios 请求头添加字段
export const tokenName = 'Authorization'
export const refreshTokenName = 'X-Authorization'
// token在localStorage、sessionStorage、cookie存储的key的名称
export const tokenTableName = `Authorization`
// token在localStorage、sessionStorage、cookie存储的key的名称
export const refreshTokenTableName = `X-Authorization`
// token存储位置 localStorage sessionStorage cookies
export const storage: StorageType = 'localStorage'
// API 统一请求路径
export const apiPrefix = 'api'
// 最长请求时间
export const requestTimeout = 200000
// 消息框消失时间
export const messageDuration = 3000
// 操作正常code，支持String、Array、int多种类型
export const successCode: Array<number> = [200]
// 登录请求终端类型( 苹果 App:IosApp\ 安卓 App：AndroidApp\ web管理端: Dashboard\ 律师个人中心: Lawyer\ 消费者个人中心: Consumer 【预留】\ 微信小程序: WechatApp 【预留】)
export const terminal = 'Lawyer'
// API 版本号
export const servicekey = '?servicekey=v1'
// 登录地址 登录时需进行特殊操作
export const loginAPIUrl = '/api/Account/Login'
// 数据请求加载文本
export const loadingTitle = '数据加载中...'
// api 服务器 接口 回调域名
export const callBackServerUrl = process.env.VITE_APP_API_BASE_URL

// 默认组件尺寸
export const componentSize = 'middle'
// 权限检查
export const enablePermission = true
// 只有一页时是否隐藏分页器
export const hideOnSinglePage = false
// 指定每页可以显示多少条
export const pageSizeOptionsDefault = ['15', '30', '60', '90', '120', '240'] //['20', '40', '80', '120', '200','300']

// 密码正则  6-16 位 允许任何字符 .{6,16}$
export const passWordReg0 = /^.{6,16}$/
// 密码正则  至少包含数字跟字母，可以有字符 (?=.*([a-zA-Z].*))(?=.*[0-9].*)[a-zA-Z0-9-*/+.~!@#$%^&*()]{6,16}$ 目前使用
export const passWordReg = /^(?=.*([a-zA-Z].*))(?=.*[0-9].*)[a-zA-Z0-9-*/+.~!@#$%^&*()]{6,16}$/
// 密码正则 密码包含 数字,英文,字符中的两种以上，长度6-20   "^(?![0-9]+$)(?![a-z]+$)(?![A-Z]+$)(?!([^(0-9a-zA-Z)])+$).{6,16}$"
export const passWordReg1 = /^(?![0-9]+$)(?![a-z]+$)(?![A-Z]+$)(?!([^(0-9a-zA-Z)])+$).{6,16}$/
// 密码正则 密码至少包含 数字和英文，长度6-20  "^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,16}$"
export const passWordReg2 = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,16}$/
// 手机号正则
export const phoneNumberReg = /^1[3,4,5,6,7,8,9][0-9]{9}$/
// 身份证号 正则
export const idCardNoReg =
  /(^[1-9]\d{5}(18|19|20|21|22|23|24|25|26|27|28|29|30|31|32|33|34|35|36|37|38|39|40)\d{2}((0[1-9])|10|11|12)(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$)|(^[1-9]\d{5}\d{2}((0[1-9])|10|11|12)(([0-2][1-9])|10|20|30|31)\d{3}$)/
// 免费案件类型Id
export const freeContractTypeID = 13

// 意见默认备注
// 审批我的(提交风控审批)
export const defaultOptionPassMy = '审批：本人已充分知悉利冲规则，并已对案件进行利冲检索，确认本案件不存在利益冲突。若本案存在利益冲突情形，本人积极配合律所处理，包括但不限于解除退费。'
// 审批团队成员
export const defaultOptionPassPartner = '审批：经查，对该案件对无异议,予以审批通过。'
// 风控审批案件
export const defaultOPtionPass = '审批：经查，该案件无风险冲突，予以审批通过。'
// 风控盖章
export const defaultOPtionSign = '盖章：经查,该案件OA信息和合同原件一致,予以盖章通过。'
// 风控结案
export const defaultOptionEnd = '结案：经查,该案件OA信息和案件信息一致,予以结案通过。'
// 风控归档
export const defaultOptionArchived = '归档：经查,该案件OA信息和案件信息一致,予以归档通过。'
// 招投标备案审核
export const defaultOptionPassBid = '审批:经查,该招标无风险冲突,予以审批通过'

export default {
  title,
  titleSeparator,
  devPort,
  cookiePrefix,
  storage,
  apiPrefix,
  requestTimeout,
  messageDuration,
  successCode,
  terminal,
  servicekey,
  titleReverse,
  defaultOptionPassMy,
  defaultOptionPassPartner
}
