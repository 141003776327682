import { Component } from 'vue'

import { MenuDataItem } from '@/router/typing'
import RouteView from '@/layouts/routeView.vue'

export const Market: MenuDataItem = {
  path: 'market',
  name: 'market',
  meta: { title: '市场管理', hideInMenu: true, hideChildrenInMenu: true, icon: 'oa_xiansuo', fullPath: '/market' },
  component: RouteView,
  redirect: '/market/index',
  children: [
    {
      path: 'index',
      name: 'market-index',
      meta: { icon: 'oa_xiansuo', title: '线索管理', fullPath: '/market' },
      component: (): Component => import('@/views/market/index.vue')
    },
    {
      path: 'marketcreate',
      name: 'market-marketcreate',
      meta: { icon: 'oa_xiansuo', hideInMenu: true, title: '创建线索', fullPath: '/market/marketcreate' },
      component: (): Component => import('@/views/market/marketcreate.vue')
    },
    {
      path: 'marketupdate/:id',
      name: 'market-marketupdate-id',
      meta: { icon: 'oa_xiansuo', hideInMenu: true, title: '编辑线索', fullPath: '/market/marketupdate/id' },
      component: (): Component => import('@/views/market/marketcreate.vue')
    },
    {
      path: 'worklog/:id',
      name: 'market-market-worklog-id',
      meta: { icon: 'oa_xiansuo', hideInMenu: true, title: '工作日志管理', fullPath: '/market/market/worklog' },
      component: (): Component => import('@/views/market/worklog.vue')
    }
  ]
}
