import { Component } from 'vue'

import { MenuDataItem } from '@/router/typing'
import RouteView from '@/layouts/routeView.vue'

export const Finance: MenuDataItem = {
  path: 'finance',
  name: 'finance',
  meta: { title: '财务管理', icon: 'oa_zhang', fullPath: '/finance' },
  component: RouteView,
  redirect: '/finance/invoce',
  children: [
    {
      path: 'myaccount',
      name: 'finance/myaccount',
      meta: { icon: 'oa_invoice', title: '我的台账', fullPath: '/finance/myaccount' },
      component: (): Component => import('@/views/finance/myAccount.vue')
    },
    {
      path: 'caseInfo/:contractId(\\d+)',
      name: 'finance-caseInfo',
      meta: { icon: 'oa_invoice', hideInMenu: true, title: '案件详情', fullPath: '/finance/caseInfo' },
      component: (): Component => import('@/views/finance/caseInfo.vue')
    },
    {
      path: 'invoicelist',
      name: 'finance-invoicelist',
      meta: { icon: 'oa_invoice', title: '开票', fullPath: '/finance/invoicelist' },
      component: (): Component => import('@/views/finance/invoiceList.vue')
    },
    {
      path: 'invoice/update/:contractId(\\d+)/:invoiceid/:code?',
      name: 'finance-invoice-update',
      meta: { icon: 'oa_invoice', hideInMenu: true, title: '编辑开票', fullPath: '/finance/invoice/update' },
      component: (): Component => import('@/views/finance/updateInvoice.vue')
    },
    {
      path: 'paymentlist',
      name: 'finance-paymentlist',
      meta: { icon: 'oa_pay', title: '缴费', fullPath: '/finance/paymentlist' },
      component: (): Component => import('@/views/finance/paymentList.vue')
    },
    {
      path: 'payment/update/:contractId(\\d+)/:paymentid/:code?',
      name: 'finance-payment-update',
      meta: { icon: 'oa_pay', hideInMenu: true, title: '编辑缴费', fullPath: '/finance/payment/update' },
      component: (): Component => import('@/views/finance/updatePayment.vue')
    },
    {
      path: 'refundlist',
      name: 'finance-refundlist',
      meta: { icon: 'oa_refund', title: '退款', fullPath: '/finance/refundlist' },
      component: (): Component => import('@/views/finance/refundList.vue')
    }
  ]
}
