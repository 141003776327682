import { Component } from 'vue'

import { MenuDataItem } from '@/router/typing'
import RouteView from '@/layouts/routeView.vue'

export const Letter: MenuDataItem = {
  path: 'letter',
  name: 'manage-letter',
  meta: { title: '函书管理', role: 'Admin.Letter', icon: 'oa_letter', fullPath: '/manage/letter' },
  component: RouteView,
  redirect: '/manage/letter/list',
  children: [
    {
      path: 'enterlist',
      name: 'manage-letter-enter-list',
      meta: { icon: 'oa_letter', role: 'Admin.Letter.Create', title: '函书登记', fullPath: '/manage/letter/enterlist' },
      component: (): Component => import('@/views/manage/letter/enter.vue')
    },
    {
      path: 'guide/:contractId(\\d+)/:contractType(\\d+)',
      name: 'manage-letter-guide',
      meta: { icon: 'oa_letter', role: 'Admin.Letter.Create', title: '函书申领', hideInMenu: true, fullPath: '/manage/letter/enter/guide' },
      component: (): Component => import('@/views/letter/guide.vue')
    },
    {
      path: 'apply/:type/:contractId(\\d+)/:region?',
      name: 'manage-letter-apply',
      meta: { icon: 'oa_letter', role: 'Admin.Letter.Create', hideInMenu: true, title: '函书申领', fullPath: '/manage/letter/apply' },
      component: (): Component => import('@/views/letter/apply.vue')
    },
    {
      path: 'list',
      name: 'manage-letter-list',
      meta: { icon: 'oa_letter', role: 'Admin.Letter.Pass', title: '函书审核', fullPath: '/manage/letter/list' },
      component: (): Component => import('@/views/manage/letter/index.vue')
    },
    {
      path: 'pass/:letterId(\\d+)/:contractId(\\d+)/:letterType',
      props: true,
      name: 'manage-letter-pass',
      meta: { icon: 'oa_letter', role: 'Admin.Letter.Pass', hideInMenu: true, title: '函书审核', fullPath: '/manage/letter/pass' },
      component: (): Component => import('@/views/manage/letter/pass.vue')
    },
    {
      path: 'info/:contractId(\\d+)',
      props: true,
      name: 'manage-letter-info',
      meta: { icon: 'oa_letter', role: 'Admin.Letter', hideInMenu: true, title: '函书详情', fullPath: '/manage/letter/info' },
      component: (): Component => import('@/views/manage/letter/info.vue')
    },
    {
      path: 'update/:type/:contractId(\\d+)/:letterId?',
      name: 'manage-letter-apply-update',
      meta: { icon: 'oa_letter', role: 'Admin.Letter.Update', hideInMenu: true, title: '函书编辑', fullPath: '/manage/letter/update' },
      component: (): Component => import('@/views/letter/apply.vue')
    }
  ]
}
