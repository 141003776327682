import { Component } from 'vue'

import { MenuDataItem } from '@/router/typing'
import RouteView from '@/layouts/routeView.vue'

export const Finance: MenuDataItem = {
  path: 'finance',
  name: 'manage-finance',
  meta: { title: '财务管理', role: 'Admin.Finance', icon: 'oa_zhang', fullPath: '/manage/finance' },
  component: RouteView,
  redirect: '/manage/finance/invoiceList',
  children: [
    //案件管理
    {
      path: 'caseList',
      name: 'manage-finance-case-index',
      meta: { icon: 'oa_yewuguanli', role: 'Admin.Finance.Manage', title: '财务管理', fullPath: '/manage/finance/caseList' },
      component: (): Component => import('@/views/manage/finance/case/index.vue')
    },
    {
      path: 'caseInfo/:contractId(\\d+)',
      name: 'manage-finance-caseInfo',
      meta: { icon: 'oa_invoice', role: 'Admin.Finance.Show', hideInMenu: true, title: '案件详情', fullPath: '/manage/finance/caseInfo' },
      component: (): Component => import('@/views/manage/finance/caseInfo.vue')
    },
    // 开票管理
    {
      path: 'invoiceList',
      name: 'manage-finance-invoice-list',
      meta: { icon: 'oa_invoice', role: 'Admin.Finance.Invoice', title: '发票管理', fullPath: '/manage/finance/invoicelist' },
      component: (): Component => import('@/views/manage/finance/invoice/index.vue')
    },
    //添加发票
    {
      path: 'case/invoicecreate/:contractId(\\d+)',
      name: 'manage-finance-case-invoicecreate',
      meta: { icon: 'oa_yewuguanli', role: 'Admin.Finance.Invoice', hideInMenu: true, title: '添加发票', fullPath: '/manage/finance/case/invoicecreate' },
      component: (): Component => import('@/views/manage/finance/case/invoiceCreate.vue')
    },
    {
      path: 'invoice/update/:contractId(\\d+)/:invoiceid?',
      name: 'manage-finance-invoice-update',
      meta: { icon: 'oa_invoice', role: 'Admin.Finance.Invoice', hideInMenu: true, title: '编辑开票', fullPath: '/manage/finance/invoice/update' },
      component: (): Component => import('@/views/manage/finance/invoice/update.vue')
    },
    // 缴费管理
    {
      path: 'paymentList',
      name: 'manage-finance-payment-list',
      meta: { icon: 'oa_pay', role: 'Admin.Finance.Payment', title: '收费管理', fullPath: '/manage/finance/paymentlist' },
      component: (): Component => import('@/views/manage/finance/payment/index.vue')
    },
    //添加缴费记录
    {
      path: 'case/paymentcreate/:contractId(\\d+)',
      name: 'manage-finance-case-paymentcreate',
      meta: { icon: 'oa_yewuguanli', role: 'Admin.Finance.Payment', hideInMenu: true, title: '添加收款记录', fullPath: '/manage/finance/case/paymentcreate' },
      component: (): Component => import('@/views/manage/finance/case/paymentCreate.vue')
    },
    {
      path: 'payment/update/:contractId(\\d+)/:paymentId(\\d+)',
      name: 'manage-finance-payment-update',
      meta: { icon: 'oa_pay', role: 'Admin.Finance.Payment', hideInMenu: true, title: '编辑缴费', fullPath: '/manage/finance/payment/update' },
      component: (): Component => import('@/views/manage/finance/payment/update.vue')
    },
    // 退款
    {
      path: 'refundList',
      name: 'manage-finance-refund-list',
      meta: { icon: 'oa_refund', role: 'Admin.Finance.Refund', title: '退款管理', fullPath: '/manage/finance/refundlist' },
      component: (): Component => import('@/views/manage/finance/refund/index.vue')
    },
    //添加退款记录
    {
      path: 'case/refundcreate/:contractId(\\d+)',
      name: 'manage-finance-case-refundcreate',
      meta: { icon: 'oa_yewuguanli', role: 'Admin.Finance.Refund', hideInMenu: true, title: '添加退款记录', fullPath: '/manage/finance/case/refundcreate' },
      component: (): Component => import('@/views/manage/finance/case/refundCreate.vue')
    },
    {
      path: 'refund/update/:contractId(\\d+)/:refundId(\\d+)',
      name: 'manage-finance-refund-update',
      meta: { icon: 'oa_pay', role: 'Admin.Finance.Refund', hideInMenu: true, title: '编辑退款', fullPath: '/manage/finance/refund/update' },
      component: (): Component => import('@/views/manage/finance/refund/update.vue')
    },
    // 台账
    {
      path: 'myaccount',
      name: 'manage-finance-myaccount-list',
      meta: { icon: 'oa_pay', role: 'Admin.Finance.Account', title: '台账列表', fullPath: '/manage/finance/myaccount' },
      component: (): Component => import('@/views/manage/finance/myaccount/index.vue')
    },
    {
      path: 'myaccountInfo/:id(\\d+)',
      name: 'manage-finance-myaccountinfo',
      meta: { icon: 'oa_pay', role: 'Admin.Finance.Account', hideInMenu: true, title: '台账详情', fullPath: '/manage/finance/myaccountInfo' },
      component: (): Component => import('@/views/manage/finance/myaccount/info.vue')
    },
    // 预存
    {
      path: 'prestoreList',
      name: 'manage-finance-prestore-list',
      meta: { icon: 'oa_invoice', role: 'Admin.Finance.PrestoredInvoice', title: '预存发票', fullPath: '/manage/finance/prestorelist' },
      component: (): Component => import('@/views/manage/finance/prestore/index.vue')
    },
    {
      path: 'prestoreInfo/:id(\\d+)',
      name: 'manage-finance-prestoreinfo',
      meta: { icon: 'oa_pay', role: 'Admin.Finance.PrestoredInvoice', hideInMenu: true, title: '预存发票详情', fullPath: '/manage/finance/prestoreInfo' },
      component: (): Component => import('@/views/manage/finance/prestore/info.vue')
    },
    // 提款
    {
      path: 'drawList',
      name: 'manage-finance-draw-list',
      meta: { icon: 'oa_invoice', role: 'Admin.Finance.Draw', title: '提款管理', fullPath: '/manage/finance/drawList' },
      component: (): Component => import('@/views/manage/finance/draw/index.vue')
    },

    {
      path: 'drawInfo/:id(\\d+)',
      name: 'manage-finance-drawinfo',
      meta: { icon: 'oa_pay', role: 'Admin.Finance.Draw', hideInMenu: true, title: '提款详情', fullPath: '/manage/finance/drawInfo' },
      component: (): Component => import('@/views/manage/finance/draw/info.vue')
    },
    // 借款
    {
      path: 'loanList',
      name: 'manage-finance-loan-list',
      meta: { icon: 'oa_invoice', role: 'Admin.Finance.Loan', title: '借款管理', fullPath: '/manage/finance/loanList' },
      component: (): Component => import('@/views/manage/finance/loan/index.vue')
    },
    {
      path: 'loanInfo/:id(\\d+)',
      name: 'manage-finance-loaninfo',
      meta: { icon: 'oa_pay', role: 'Admin.Finance.Loan', hideInMenu: true, title: '借款详情', fullPath: '/manage/finance/loanInfo' },
      component: (): Component => import('@/views/manage/finance/loan/info.vue')
    },
    // 调账
    {
      path: 'changeaccountList',
      name: 'manage-finance-changeaccount-list',
      meta: { icon: 'oa_invoice', role: 'Admin.Finance.Transfer', title: '调账管理', fullPath: '/manage/finance/changeaccountlist' },
      component: (): Component => import('@/views/manage/finance/changeaccount/index.vue')
    },
    // 补充历史记录
    {
      path: 'historyList',
      name: 'manage-finance-history-list',
      meta: { icon: 'oa_invoice', role: 'Admin.Finance.Manage', title: '补充历史记录', fullPath: '/manage/finance/historylist' },
      component: (): Component => import('@/views/manage/finance/history/index.vue')
    },
    //补充发票记录
    {
      path: 'history/invoice/:contractId(\\d+)',
      name: 'manage-finance-history-invoice',
      meta: { icon: 'oa_yewuguanli', role: 'Admin.Finance.Invoice', hideInMenu: true, title: '补充发票记录', fullPath: '/manage/finance/history/invoice' },
      component: (): Component => import('@/views/manage/finance/history/invoiceCreate.vue')
    },
    //编辑补充发票记录
    {
      path: 'history/invoice/update/:contractId(\\d+)/:invoiceId(\\d+)',
      name: 'manage-finance-history-invoice-update',
      meta: { icon: 'oa_pay', role: 'Admin.Finance.Invoice', hideInMenu: true, title: '编辑补充发票记录', fullPath: '/manage/finance/history/invoiceupdate' },
      component: (): Component => import('@/views/manage/finance/history/invoiceUpdate.vue')
    },
    //补充缴费记录
    {
      path: 'history/payment/:contractId(\\d+)',
      name: 'manage-finance-history-payment',
      meta: { icon: 'oa_yewuguanli', role: 'Admin.Finance.Payment', hideInMenu: true, title: '补充收款记录', fullPath: '/manage/finance/history/payment' },
      component: (): Component => import('@/views/manage/finance/history/paymentCreate.vue')
    },
    //编辑补充缴费记录
    {
      path: 'history/payment/update/:contractId(\\d+)/:paymentId(\\d+)',
      name: 'manage-finance-history-payment-update',
      meta: { icon: 'oa_pay', role: 'Admin.Finance.Payment', hideInMenu: true, title: '编辑补充收款记录', fullPath: '/manage/finance/history/paymentupdate' },
      component: (): Component => import('@/views/manage/finance/history/paymentUpdate.vue')
    }
    // //补充退款记录
    // {
    //   path: 'history/refund/:contractId(\\d+)',
    //   name: 'manage-finance-history-refund',
    //   meta: { icon: 'oa_yewuguanli', role: 'Admin.Finance.Refund', hideInMenu: true, title: '补充退款记录', fullPath: '/manage/finance/history/refund' },
    //   component: (): Component => import('@/views/manage/finance/history/refundCreate.vue')
    // },
    // //编辑补充退款记录
    // {
    //   path: 'history/refund/update/:contractId(\\d+)/:refundId(\\d+)',
    //   name: 'manage-finance-history-refund-update',
    //   meta: { icon: 'oa_pay', role: 'Admin.Finance.Refund', hideInMenu: true, title: '编辑补充退款记录', fullPath: '/manage/finance/history/refundupdate' },
    //   component: (): Component => import('@/views/manage/finance/history/refundUpdate.vue')
    // }
  ]
}
