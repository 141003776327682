import request from '@/utils/request'
import type { RequestResult } from '../typing'
import type { ContractTypeCreateOrUpdate, ContractTypeItem } from '@/stores/modules/system/typing'
import type { CheckExist } from '@/stores/typing'

/**
 * 合同类型
 * 获取案件类型Tree列表
 * @returns {RequestResult<Array<ContractTypeItem>>}
 */
export async function getContractTypeAllTree() {
  return request.get<Array<number>, RequestResult<Array<ContractTypeItem>>>(`/api/ContractType/GetTreeList`)
}

/**
 * 获取案件类型指定节点 tree
 * @param {number} id
 * @returns {RequestResult<ContractTypeItem>}
 */
export async function getContractTypeNodeTree(id: number) {
  return request.get<number, RequestResult<ContractTypeItem>>(`/api/ContractType/GetTree/${id}`)
}

/**
 * 获取案件类型节点ID下子节点
 * @param {number} id
 * @returns {RequestResult<Array<ContractTypeItem>>}
 */
export async function getContractTypeChildTree(id: number) {
  return request.get<number, RequestResult<Array<ContractTypeItem>>>(`/api/ContractType/GetChildList/${id}`)
}

/**
 * 根据ID获取案件类型详情
 * @param {number} id
 * @returns {RequestResult<ContractTypeItem>}
 */
export async function getContractTypeInfo(id: number) {
  return request.get<number, RequestResult<ContractTypeItem>>(`/api/ContractType/GetById/${id}`)
}

/**
 * 检查是否重复
 * @param {CheckExist} CheckExist
 * @returns {RequestResult<ContractTypeItem>}
 */
export async function checkContractTypeName(CheckExist: CheckExist) {
  return request.post<CheckExist, RequestResult<boolean>>(`/api/ContractType/CheckExist`, CheckExist)
}

/**
 * 创建案件类型
 * @param {ContractTypeCreateOrUpdate} data
 * @returns {RequestResult}
 */
export async function createContractType(data: ContractTypeCreateOrUpdate) {
  return request.post<ContractTypeItem, RequestResult<null>>(`/api/ContractType/create`, data)
}

/**
 * 编辑案件类型
 * @param {ContractTypeCreateOrUpdate} data
 * @returns {RequestResult}
 */
export async function updateContractType(data: ContractTypeCreateOrUpdate) {
  return request.put<ContractTypeItem, RequestResult<null>>(`/api/ContractType/update`, data)
}

/**
 * 删除案件类型
 * @param {ContractTypeItem} data
 * @returns {RequestResult}
 */
export async function deleteContractType(id: number) {
  return request.delete<number, RequestResult<null>>(`/api/ContractType/delete/${id}`)
}

/**
 * 软删除案件类型
 * @param {ContractTypeItem} data
 * @returns {RequestResult}
 */
export async function deleteContractTypeSoft(id: number) {
  return request.post<number, RequestResult<null>>(`/api/ContractType/SoftDelete/${id}`)
}
