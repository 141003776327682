import { defineStore } from 'pinia'
import { CaseReasonItem } from './typing'
import { getCaseReasonAllTree } from '@/api/system/caseReason'
export const SystemCaseReason = defineStore('SystemCaseReason', {
  state: () => {
    return {
      tree: [] as Array<CaseReasonItem>
    }
  },
  getters: {
    caseReasonTree: state => state.tree as Array<CaseReasonItem>
  },
  actions: {
    async getCaseReasoTree(idList: Array<number> = []) {
      try {
        const res = await getCaseReasonAllTree(idList)
        const { data, succeeded } = res
        succeeded ? (this.tree = data) : ''
        return res
      } catch (error) {}
    }
  }
})
