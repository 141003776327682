import { Component } from 'vue'

import { MenuDataItem } from '@/router/typing'
import RouteView from '@/layouts/routeView.vue'

export const Letter: MenuDataItem = {
  path: 'letter',
  name: 'letter',
  meta: { title: '函书申领', hideInMenu: true, hideChildrenInMenu: true, icon: 'oa_letter', fullPath: '/letter' },
  component: RouteView,
  redirect: '/letter/index',
  children: [
    {
      path: 'index',
      name: 'letter-index',
      meta: { icon: 'oa_letter', title: '函书申领列表', fullPath: '/letter/index' },
      component: (): Component => import('@/views/letter/index.vue')
    },
    {
      path: 'guide/:contractId(\\d+)/:contractType(\\d+)',
      name: 'letter-guide',
      meta: { icon: 'oa_letter', title: '函书申领', fullPath: '/letter/guide' },
      component: (): Component => import('@/views/letter/guide.vue')
    },
    {
      path: 'apply/:type/:contractId(\\d+)/:region?',
      name: 'letter-apply',
      meta: { icon: 'oa_letter', hideInMenu: true, title: '函书申领', fullPath: '/letter/apply' },
      component: (): Component => import('@/views/letter/apply.vue')
    }
  ]
}
