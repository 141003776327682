import { defineStore } from 'pinia'
import { getOrganizationAllTreeList } from '@/api/system/organization'
import type { OrganizationItem, OrganizationListItem, OrganizationType } from './typing'

export enum OrganizationTypeCode {
  PublicSecurity = 0, //公安
  Court = 1, //法院
  Procuratorate = 2, //检查
  ArbitrationCommission = 3, //仲裁
  NonSuit = 4, //非诉主管机构
  Other = 99 //其他
}

export const SystemOrgainzation = defineStore('SystemOrgainzation', {
  state: () => {
    return {
      type: {
        [OrganizationTypeCode.PublicSecurity]: { key: OrganizationTypeCode.PublicSecurity, title: '公安', color: 'success' },
        [OrganizationTypeCode.Court]: { key: OrganizationTypeCode.Court, title: '法院', color: 'success' },
        [OrganizationTypeCode.Procuratorate]: { key: OrganizationTypeCode.Procuratorate, title: '检察', color: 'success' },
        [OrganizationTypeCode.ArbitrationCommission]: { key: OrganizationTypeCode.ArbitrationCommission, title: '仲裁', color: 'success' },
        [OrganizationTypeCode.NonSuit]: { key: OrganizationTypeCode.NonSuit, title: '非诉主管机构', color: 'success' },
        [OrganizationTypeCode.Other]: { key: OrganizationTypeCode.Other, title: '其他', color: 'success' }
      } as OrganizationType,
      data: [] as Array<OrganizationItem>,
      publicSecurity: [] as Array<OrganizationListItem>,
      court: [] as Array<OrganizationListItem>,
      procuratorate: [] as Array<OrganizationListItem>,
      arbitrationCommission: [] as Array<OrganizationListItem>,
      nonSuit: [] as Array<OrganizationListItem>,
      other: [] as Array<OrganizationListItem>
    }
  },
  getters: {
    OrganizationType: state => state.type as OrganizationType,
    PublicDecryptData: state => state.publicSecurity as Array<OrganizationListItem>,
    CourtData: state => state.court as Array<OrganizationListItem>,
    ProcuratorateData: state => state.procuratorate as Array<OrganizationListItem>,
    ArbitrationCommissionData: state => state.arbitrationCommission as Array<OrganizationListItem>,
    NonSuitData: state => state.nonSuit as Array<OrganizationListItem>,
    OtherData: state => state.other as Array<OrganizationListItem>,
    getOrganizationTypeTreeData: state => (type?: OrganizationTypeCode) => {
      const list = type !== undefined ? state.data.find(item => item.organizationType === type) : { organizationType: undefined, organizationList: [] }
      return list?.organizationList
    }
  },
  actions: {
    async getOrganizationData() {
      try {
        const res = await getOrganizationAllTreeList()
        const { data, succeeded } = res
        if (succeeded) {
          this.data = data
          data.forEach(item => {
            switch (item.organizationType) {
              case 0:
                return (this.publicSecurity = item.organizationList)
              case 1:
                return (this.court = item.organizationList)
              case 2:
                return (this.procuratorate = item.organizationList)
              case 3:
                return (this.arbitrationCommission = item.organizationList)
              case 4:
                return (this.nonSuit = item.organizationList)
              default:
                return (this.other = item.organizationList)
            }
          })
        }
        return res
      } catch (error) {}
    }
  }
})
