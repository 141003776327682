import { IndustryCreateOrUpdate, IndustryItem } from '@/stores/modules/system/typing'
import request from '@/utils/request'
import { RequestResult } from '../typing'
import { CheckExist } from '@/stores/typing'

/**
 * 获取行业Tree
 * @param {Array<number>} params
 * @returns {RequestResult<Array<IndustryItem>>}
 */
export async function getIndustryAllTree(idList: Array<number>) {
  return request.get<Array<number>, RequestResult<Array<IndustryItem>>>(`/api/Industry/GetTreeList`, { params: idList })
}

/**
 * 获取 指定节点 tree
 * @param {number} id
 * @returns {RequestResult<IndustryItem>}
 */
export async function getIndustryNodeTree(id: number) {
  return request.get<number, RequestResult<IndustryItem>>(`/api/Industry/GetTree/${id}`)
}
/**
 * 获取节点ID下子节点
 * @param {number} id
 * @returns {RequestResult<Array<IndustryItem>>}
 */
export async function getIndustryChildTree(id: number) {
  return request.get<number, RequestResult<Array<IndustryItem>>>(`/api/Industry/GetChildList/${id}`)
}

/**
 * 根据Id 获取行业详情
 * @param {number} id
 * @returns {RequestResult<IndustryItem>}
 */
export async function getIndustryInfo(id: number) {
  return request.get<number, RequestResult<IndustryItem>>(`/api/Industry/GetById/${id}`)
}

/**
 * 检查是否重复
 * @param {CheckExist} CheckExist
 * @returns {RequestResult<boolean>}
 */
export async function checkIndustryName(CheckExist: CheckExist) {
  return request.post<CheckExist, RequestResult<boolean>>(`/api/Industry/CheckExist`, CheckExist)
}

/**
 * 添加行业
 * @param {IndustryCreateOrUpdate} industry
 * @returns {RequestResult<null>}
 */
export async function createIndustry(industry: IndustryCreateOrUpdate) {
  return request.post<IndustryCreateOrUpdate, RequestResult<null>>('/api/Industry/Create', industry)
}

/**
 * 编辑行业
 * @param {IndustryCreateOrUpdate} industry
 * @returns {RequestResult<null>}
 */
export async function updateIndustry(industry: IndustryCreateOrUpdate) {
  return request.put<IndustryCreateOrUpdate, RequestResult<null>>('/api/Industry/Update', industry)
}

/**
 * 删除行业
 * @param {number} id
 * @returns {RequestResult<null>}
 */
export async function deleteIndustry(id: number) {
  return request.delete<number, RequestResult<null>>(`/api/Industry/Delete/${id}`)
}

/**
 * 软删除行业
 * @param {number} id
 * @returns {RequestResult<null>}
 */
export async function deleteIndustrySoft(id: number) {
  return request.post<number, RequestResult<null>>(`/api/Industry/SoftDelete/${id}`)
}
